import Vue from 'vue';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import { Button,  Tag, Container, Aside, Main, Dialog, Form, Input, FormItem, Card, Col, Row, Header, Image, Tabs, TabPane, Popover, Badge, Avatar, Table,  DatePicker, TableColumn,  Collapse, CollapseItem, Checkbox, Progress, Empty, Message,  Radio, RadioGroup,  Pagination, Carousel, CarouselItem, Select, Option, Notification} from 'element-ui'

const element = {
    install: (Vue) => {
        Vue.use(Button).use(Tag).use(Container).use(Aside).use(Main).use(Dialog).use(Form).use(Input).use(FormItem).use(Card).use(Col).use(Row).use(Header).use(Image).use(Tabs).use(TabPane).use(Popover).use(Badge).use(Avatar).use(Table).use(DatePicker).use(TableColumn).use(Collapse).use(CollapseItem).use(Checkbox).use(Progress).use(Empty).use(Radio).use(RadioGroup).use( Pagination).use(Carousel).use(CarouselItem).use(Select).use(Option)
    }
}
// 设置语言
locale.use(lang)
Vue.prototype.$message = Message;
Vue.prototype.$notification = Notification;
export default element