import http from '../utils/request'

export const userinfoApi = {
    // 获取个人中心 vip信息
    gamevipsInfo: () => {
        return http({
            url: 'index/vips_info',
            method: 'POST',
        })
    },

    // 获取充值提现记录
    rechargeRecord: (data) => {
        return http({
            url: 'index/Wit_rech_list',
            method: 'POST',
            data
        })
    },

    // 游戏流水记录
    gameRecord: (data) => {
        return http({
            url: 'index/find_bets',
            method: 'POST',
            data
        })
    },

    // 游戏返利记录
    gameRebate: (data) => {
        return http({
            url: 'index/rebate_list',
            method: 'POST',
            data
        })
    },

      // 邀请函详细信息记录
      gameInvitation: (data) => {
        return http({
            url: 'index/getthree',
            method: 'POST',
            data
        })
    },
}
