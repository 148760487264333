
<template>
  <div class="game_layout">
    <el-container class="el_container">
      <el-header
        class="game_header card_background game_flex game_align_center"
      >
        <div @click="$store.dispatch('setShowLeftMenu', !showLeftMenu)">
          <i
            style="font-size: 0.2rem"
            :class="showLeftMenu ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          ></i>
        </div>
        <div
          class="game_flex1"
          @click="$router.push({ path: '/' })"
          style="cursor: pointer"
        >
          <el-image
            style="width: 1.0417rem; height: 0.2rem"
            :src="require('../assets/logo.png')"
          ></el-image>
        </div>
        <div class="header_fr game_flex">
          <div v-if="!userInfo?.token" class="have_logged">
            <el-button type="primary" size="mini" @click="login(1)">{{
              $t("jogos.text24")
            }}</el-button>
            <el-button type="primary" size="mini" @click="login(2)">{{
              $t("jogos.text25")
            }}</el-button>
          </div>

          <div v-else class="not_logged game_flex">
            <div class="one game_flex game_align_center">
              <span class="balance_txt">R$ {{ userInfo.money }}</span>
              <span
                class="balance_btn game_flex game_align_center game_content_center"
                @click="$store.dispatch('setAccessMode', 0), $store.dispatch('setAccountDialog', true)"
              >
                <i class="el-icon-s-finance" style="font-size: 0.1rem"></i>
              </span>
            </div>
            <div class="game_flex game_align_center" style="cursor: pointer">
              <el-popover
                ref="popoverRef"
                placement="right"
                width="400"
                trigger="click"
                style="background-color: #211e2f"
              >
                <game-popover-info :doClosePopover="doClosePopover" />
                <div
                  slot="reference"
                  class="game_flex game_align_center infos_last"
                >
                  <el-avatar size="large" :src="userInfo.avatar"></el-avatar>
                  <i
                    class="el-icon-arrow-down"
                    style="font-size: 18px; margin-left: 0.0833rem"
                  ></i>
                </div>
              </el-popover>
            </div>
          </div>

          <div class="switch_language game_flex game_align_center">
            <el-popover
                ref="popoverRef"
                placement="right"
                width="160"
                trigger="hover"
                style="background-color: #211e2f"
              >
              <game-language />
            <div slot="reference" style="width: 100px; text-align: center;">
              <span> {{ language }} </span>
            <i class="el-icon-refresh"></i>
            </div>
            </el-popover>
          
          </div>
        </div>
      </el-header>

      <el-container>
        <el-aside
          width="240px"
          class="game_aside card_background"
          v-show="showLeftMenu"
        > 
          <div class="d_fl_one game_border_r game_flex" @click="toPath({path: '/convidar'})"
          style="cursor: pointer;"
          >
            <div class="tit">{{ $t("jogos.text1") }}</div>
            <div class="con">{{ $t("jogos.text2") }}</div>
          </div>

          <div class="d_fl_tow game_border_r game_flex">
            <div
              v-for="item in leftList"
              :key="item.gameCode"
              class="tow_item game_border_r"
              :style="{
                background: `url(${item.imageUrl}) 100% / 100% no-repeat`,
              }"
            >
              <div class="tit" @click="enterGameBtn(item)">
                {{ item.gameName }}
              </div>
            </div>
          </div>

          <div class="d_fl_three">
            <el-button
              type="primary"
              size="mini"
              @click="allBtn"
              style="width: 90%"
            >
              {{ $t("jogos.text9") }}
            </el-button>
          </div>

          <div class="d_fl_four">
            <div
              class="rows game_flex game_border_r game_align_center"
              v-for="item in menuList"
              :key="item.url"
              @click="toPath(item)"
            >
              <el-image :src="item.url" style="width: 20px"></el-image>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </el-aside>
        <el-container>
          <el-main class="el_main">
            <transition mode="out-in">
              <div class="el_content">
                <router-view />
              </div>
            </transition>
            <game-footer :leftList="leftList"/>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
    <game-tabbar />
    <game-dialog />
    <game-susp />
    <game-account />
    <game-conversion />
    <game-announcement :content="announcementData" />

    <!-- <game-animations /> -->
    
  </div>
</template>
  
<script>
import gameDialog from "./game-dialog";
import gameFooter from "./game-footer";
import gameSusp from "./game-susp";
import gamePopoverInfo from "./game-popover-info";
import gameAccount from "./game-account";
import gameConversion from "./game-conversion";
import gameTabbar from "./game-tabbar";
import gameAnnouncement from "./game-announcement";
import gameLanguage from './game-language'
import gameAnimations from './game-animations'

import { mapGetters } from "vuex";

import { homeApi } from "@/api/home.js";

export default {
  name: "shIndex",
  components: {
    gameDialog,
    gameFooter,
    gameSusp,
    gamePopoverInfo,
    gameAccount,
    gameConversion,
    gameTabbar,
    gameAnnouncement,
    gameLanguage,
    gameAnimations
  },
  computed: {
    ...mapGetters(["showLeftMenu", "userInfo", "language"]),
  },
  created() {
    this.getDownloadAddress();
    this.getLeft();
    this.getAnnouncementData();
    this.getInformation()
  },
  data() {
    return {
      downloadLink: "",
      leftList: [],
      menuList: [
        {
          url: require("@/assets/images/jogos/coupon-code.png"),
          name: this.$t("jogos.text10"),
          path: "/coupon",
        },
        {
          url: require("@/assets/images/jogos/recharge.png"),
          name: this.$t("jogos.text11"),
          path: "/article",
        },
        {
          url: require("@/assets/images/jogos/clube-vip.png"),
          name: this.$t("jogos.text12"),
          path: "/vip",
        },
        {
          url: require("@/assets/images/jogos/tg.png"),
          name: this.$t("jogos.text13"),
          path: ''
        },
      ],
      announcementData: [],
    };
  },
  methods: {
    login(index) {
      this.$store.dispatch('setLoginNavIndex', index)
      this.$store.dispatch("setLoginDialog", true);
    },
    async getDownloadAddress() {
      let { code, data } = await homeApi.downloadAddress();
      if (code == 200) {
        this.downloadLink = data;
      }
    },
    download() {
      window.location.href = this.downloadLink.appd;
    },

    logOutBtn() {
      this.$store.dispatch("LogOut").then(() => {});
    },

    async refresh() {
      const { code, data } = await homeApi.getInfo();
      if (code == 200) {
        this.$store.dispatch("setUserInfo", data);
        this.$message.success("刷新成功");
      }
    },

    allBtn() {
      this.$router.push({ path: "/" });
    },

    async getLeft() {
      let { code, data } = await homeApi.getLeft();
      if (code == 200) {
        this.leftList = data;
      }
    },

    // 进入游戏
    async enterGameBtn(row) {
      this.$router.push({ path: `/game-intro?id=${row.id}` });
    },

    // 获取首页公告
    async getAnnouncementData() {
      let { code, data } = await homeApi.announcementData();
      if (code == 200) {
        this.announcementData = data.data;
        this.$store.dispatch("setAnnouncementDialog", true);
      }
    },
    toPath(item){
				const reg = /^https:\/\//i; 
				this.$delay(200).then(() => {
					// 外部链接
					if(reg.test(item.path)){
						return window.location.href = item.path
					}
          this.$router.push({path: item.path})
				})
			},
      async getInformation(){
				let {code,data} = await homeApi.information()
				if(code == 200){
					this.menuList[3].path = data.chat
				}
			},
    doClosePopover() {
      this.$refs.popoverRef.doClose();
    },
  },
};
</script>
