export default {
  jogos: {
    text1: 'Recomendar',
    text2: 'Fácil de ganhar dinheiro',
    text3: 'Minas',
    text4: 'Colisão',
    text5: 'Fronteira regional',
    text6: 'Dado',
    text7: 'Dobro',
    text8: 'Dobrado',
    text9: 'Todos os jogos',
    text10: 'Código de cupom',
    text11: 'Bônus de primeiro depósito',
    text12: 'Clube VIP',
    text13: 'Ver mais promoções',
    text14: 'Todos',
    text15: 'Por favor, digite',
    text16: 'Aposta',
    text17: 'Notificação',
    text18: 'Depósito',
    text19: 'Retirada',
    text20: 'Nível VIP',
    text21: 'Conta',
    text22: 'Registro',
    text23: 'Sair',
    text24: 'Entrar',
    text25: 'Registrar',
    text26: 'Idioma',
    text27: 'Favorito',
    text28: 'Remover da lista de favoritos',
  },
  footer: {
    text1: 'Jogador',
    text2: 'Vencedor',
    text3: 'Ajuda',
    text4: 'Política KYC',
    text5: 'Política de Privacidade',
    text6: 'Termos e Condições',
    text7: 'FAQ',
    text8: 'Sobre nós',
    text9: 'Este site oferece jogos com experiência de risco. Para ser usuário do nosso site, você deve ter pelo menos 18 anos. Não assumimos qualquer responsabilidade por ações que violem as leis de jogos online em sua localidade. Jogue com responsabilidade e divirta-se no bbrjogo | Casino online para ganhar dinheiro real em jogos de cassino online.',
    text10: 'Jogos',
  },
  tabbar: {
    text1: 'Início',
    text2: 'Compartilhar',
    text4: 'Atividades',
    text5: 'App',
  },
  bonus: {
    text1: 'Promoções',
    text2: 'Saiba mais',
  },
  download: {
    text1: 'COKE JOGO | Casino online para ganhar dinheiro real em jogos de cassino online',
  },
  convidar: {
    text1: 'Convidar',
    text2: 'Dados estatísticos',
    text3: 'Convidados',
    text4: 'Bônus',
    text5: 'Detalhes do convite',
    text6: 'Pessoas totais',
    text7: 'Renda total',
    text8: 'Pessoas de hoje',
    text9: 'Renda de hoje',
    text10: 'Comissão / Mês',
    text11: 'Converter URL',
    text12: 'Copiar link',
    text13: 'Convide amigos, junte-se à riqueza',
    text14: 'Quer ganhar mais renda facilmente? Quer que seus amigos encontrem uma maneira de enriquecer? Junte-se a nós e vamos trabalhar juntos!',
    text15: 'Resumo',
    text16: 'Classificação',
    text17: 'Nível',
    text18: 'Pessoas',
    text19: 'Primeiro depósito',
    text20: 'Total depositado',
    text21: 'Total retirado',
    text22: 'Lucro total',
  },
  userinfo: {
    text1: 'Gerenciamento de saldo',
    text2: 'Registros',
    text3: 'Convidar amigos',
    text4: 'Balanço',
    text5: 'Minha classificação VIP',
    text6: 'Aposta',
    text7: 'Todos os níveis',
    text8: 'Retirada',
    text9: 'Disponível para saque',
    text10: 'Desbloqueie seu saldo apostando',
    text11: 'Apostas',
    text12: 'Depósito',
    text13: 'Fluxo de apostas',
    text14: 'Cashback',
    text15: 'Cancelar',
    text16: 'Confirmar',
    text17: 'Data de início',
    text18: 'Data de término',
    text19: 'Nenhum dado disponível',
    text20: 'Observação',
    text21: 'Nome do jogo',
    text22: 'Aposta',
    text23: 'Valor ganho/perdido',
    text24: 'Data de criação',
    text25: 'Valor depositado',
    text26: 'Valor retirado',
    text27: 'Status',
    text28: 'Tempo',
    text29: 'Número do pedido',
    text30: 'Meta de apostas',
    text31: 'Progresso atual',
    text32: 'Remover',
    text33: 'Detalhes do convite',
    text34: 'Usuário'
  },
  vip: {
    text1: 'Qualificação pode ser desbloqueada acumulando',
    text2: 'valor e apostas válidas. Obtenha descontos mais altos nos jogos',
    text3: 'Quanto maior o nível, maior o desconto',
    text4: 'VIP',
    text5: 'Requisito de nível',
    text6: 'Aposta necessária',
    text7: 'Depósito mínimo necessário',
    text8: 'Previlégios do nível',
    text9: 'Taxa de saque',
    text10: 'Número de saques diários',
    text11: 'Limite diário de saque',
    text12: 'Recompensa semanal',
    text13: 'Recompensa mensal',
    text14: 'Bônus de upgrade de membro',
    text15: 'Limite de retirada único',
  },
  login: {
    title1: 'Digite o e-mail',
    title2: 'Digite a senha',
    title3: 'Esqueceu a senha',
    title4: 'Entrar',
    title5: 'Código de verificação',
    title6: 'Próximo passo',
    title7: 'Código de convite',
    text1: 'Obter código',
    text2: 'Reenviar',
    text3: 'Segundos para reenviar',
    text4: 'Código enviado',
    text5: 'O nome de usuário é obrigatório',
    text6: 'Digite um endereço de e-mail válido',
    text7: 'A senha é obrigatória',
    text8: 'A senha deve ter de 6 a 20 caracteres',
    text9: 'O código de verificação deve ter de 4 a 6 caracteres',
    text10: 'Recuperar senha',
    text11: 'O código de verificação é obrigatório',
    text12: 'Segundos atrás recuperar',
    text13: 'Senha',
    text14: 'Nome de usuário'
  },
  account: {
    text1: 'Depositar',
    text2: 'Retirar',
    text3: 'Quantia',
    text4: 'Digite',
    text5: 'Não participar de promoções',
    text6: 'Cancelar retirada',
    text7: 'Problemas de pagamento',
    text8: 'Clique aqui para solicitar ajuda',
    text9: 'Comprovante de depósito',
    text10: 'Depósito mínimo requerido',
    text11: 'Digite suas informações de depósito corretamente',
    text12: 'O dinheiro será creditado em até 5 minutos',
    text13: 'Se você tiver alguma dúvida, entre em contato conosco a qualquer momento',
    text14: 'Saldo disponível',
    text15: 'Quantidade',
    text16: 'Retirar para',
    text17: '',
    text18: 'Comprovante de retirada',
    text19: 'Nível',
    text20: 'Quantidade de retiradas',
    text21: 'Taxa de retirada',
    text22: 'Retirada mínima',
    text23: 'Retirada máxima',
    text24: 'Período de disponibilidade',
    text25: 'O saque mais rápido leva 10 minutos.',
    text26: 'Você deve preencher todos os campos. Os dados devem ser verdadeiros e verificados antes da retirada. Se os dados inseridos estiverem incorretos, você não receberá o pagamento e seu saldo não será reembolsado. Tenha cuidado ao preencher este formulário!',
    text27: 'Se você tiver alguma dúvida, entre em contato conosco a qualquer momento',
    text28: 'Sobrenome',
    text29: 'Nome',
    text30: 'E-mail',
    text31: 'Telefone',
    text32: 'Número de identificação fiscal',
    text33: 'Conta',
    text34: 'Selecione',
    text35: 'Salvar',
  },
  msg: {
    text1: 'Formato de e-mail inválido',
    text2: 'Registro bem-sucedido',
    text3: 'Login bem-sucedido',
  },
  coupon: {
    text1: 'Trocar',
    text2: 'Lembrar do cupom',
    text3: 'Certifique-se de que seu nome e número de conta bancária estejam corretos e únicos. Se um usuário usar vários IP de rede para registrar várias contas e obter cupons, será considerado atividade fraudulenta e sua conta será permanentemente suspensa.',
    text4: 'Troca sucedida',
  },
  // Additional
  mine: {
    text1: 'Saldo da conta',
    text2: 'Valor do jogo',
    text3: 'Total depositado',
    text4: 'Pontos de aposta',
    text5: 'Depósito',
    text6: 'Conta',
    text7: 'Outras funcionalidades',
    text8: 'Histórico do pagamento',
    text9: 'Transferência de saldo',
    text10: 'Registro da partida',
    text11: 'Configurações',
    text12: 'Sair',
    text13: 'Selecionar',
    text14: 'Transferir para',
    text15: 'Transferir para fora',
    text16: 'Saldo disponível',
    text17: 'Valor da transferência',
    text18: 'Valor da transferência',
    text19: 'Digite o valor',
    text20: 'Transferência para a carteira',
    text21: 'Transferência para o saldo',
    text22: 'Transferir jogos para o saldo',
    text23: 'Conta atual',
    text24: 'Enviar',
    text25: 'Operação bem-sucedida',
    text26: 'Excluir todos os lidos',
    text27: 'Replicar com sucesso'
  },

  paycard: {
    title1: 'Sobrenome',
    title2: 'Nome',
    title3: 'Email',
    title4: 'Telefone',
    title5: 'CPF',
    title6: 'Por favor digite',
    title7: 'Conta',
    title8: 'Tipo de Conta',
    title9: 'Formato inválido',
    title10: 'Por favor selecione'
}
}