<template>
  <div class="game_animations">
    <div class="left">
      <div
        class="icon-wrapper"
        v-for="item in imglist"
        :key="item"
        :style="{ top: randomPosition(), left: randomPosition() }"
      >
        <img :src="item" class="bg-components" />
      </div>
    </div>

    <div class="right">
      <div
        class="icon-wrapper"
        v-for="item in imglist"
        :key="item"
        :style="{ top: randomPosition(), left: randomPosition() }"
      >
        <img :src="item" class="bg-components" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "gameAnimations",
  data() {
    return {
      imglist: [
        require("@/assets/images/animations/1.svg"),
        require("@/assets/images/animations/2.svg"),
        require("@/assets/images/animations/3.svg"),
        require("@/assets/images/animations/4.svg"),
        require("@/assets/images/animations/5.svg"),
        require("@/assets/images/animations/6.svg"),
        require("@/assets/images/animations/7.svg"),
        require("@/assets/images/animations/8.svg"),
        require("@/assets/images/animations/9.svg"),
        require("@/assets/images/animations/10.svg"),
      ],
    };
  },
  methods: {
    randomPosition() {
      const randomInt = Math.floor(Math.random() * 1920); // 生成0-19200之间的随机整数
      return `${randomInt}px`; // 返回以像素为单位的值
    },
  },
};
</script>

<style lang="scss" scoped>
.game_animations {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;

  .icon-wrapper {
    position: relative;
    width: 25px;

    img {
      width: 100%;
    }
  }

  .left,
  .right {
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  .left {
    .icon-wrapper {
      position: absolute;

      @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
          animation: linear 10s bg24#{$i} infinite alternate;
          $delay: random(500) + 0ms;
          animation-delay: $delay;
          transform: translate3d(
              (random(100) * 0.0526rem),
              (random(20) * 0.0526rem),
              (random(12) * 0.0526rem)
            )
            scale(0.2);

          @keyframes bg24#{$i} {
            0% {
              transform: scale(0.5);
            }

            100% {
              transform: translate3d(0, 0, .0526rem);
            }
          }
        }
      }
    }
  }

  .right {
    .icon-wrapper {
      position: absolute;

      @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
          animation: linear 10s bg24#{$i} infinite;
          $delay: random(500) + 0ms;
          animation-delay: $delay;
          transform: translate3d(
              (random(150) * 0.0526rem),
              (random(400) * 0.0526rem),
              (random(500) * 0.0526rem)
            )
            scale(0.2);

          @keyframes bg24#{$i} {
            0%,
            50%,
            80%,
            90% {
              opacity: 0.7;
            }

            100% {
              transform: translate3d(0, 0, 17.8947rem) translateY(-1.5263rem);
              opacity: 0;
            }
          }
        }
      }
    }
  }

  & > img {
    position: absolute;
    pointer-events: none;
    user-select: none;
  }
}
</style>