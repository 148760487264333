export default {
 
    userInfo: store => store.user.userInfo,
    token: store => store.user.token,
    language: store => store.user.language,
    loginNavIndex: store => store.config.loginNavIndex,
    accessMode: store => store.config.accessMode,

    showLoginDialog: store => store.config.showLoginDialog,
    showLeftMenu: store => store.config.showLeftMenu,
    showAccountDialog: store => store.config.showAccountDialog,
    showConversionDialog: store => store.config.showConversionDialog,
    showAnnouncementDialog: store => store.config.showAnnouncementDialog,
}