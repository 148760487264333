import Vue from 'vue'
import VueI18n from 'vue-i18n';
import store from '@/store' 
//页面中自定义变量
import zh from './zh'; // 中文
import spain from './spain'
import english from './english'; // 英文
import portugal from './portugal'
Vue.use(VueI18n)
 
 
const i18n = new VueI18n({
  locale:  store.state.user.language|| 'portugal', 
  messages: {
    // zh,
    // 西班牙
    spain,
    // 葡萄牙
    portugal,
    // 英语
    english
  }
})
export default i18n