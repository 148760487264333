import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import Index from '../views/index.vue'
Vue.use(VueRouter)

import store from '@/store'

import { isLogin } from '@/utils' 
const routes = [

  {
    path: "/",
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'index',
        component: Index
      },

      // 优惠券
      {
        path: '/coupon',
        name: 'coupon',
        component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/index.vue')
      },

      // 首存  
      {
        path: '/article',
        name: 'article',
        component: () => import(/* webpackChunkName: "article" */ '../views/article/index.vue')
      },

      // 贵宾俱乐部
      {
        path: '/vip',
        name: 'vip',
        component: () => import(/* webpackChunkName: "vip" */ '../views/vip/index.vue')
      },
      // 分享
      {
        path: '/convidar',
        name: 'convidar',
        component: () => import(/* webpackChunkName: "convidar" */ '../views/convidar/index.vue')
      },
      // 活动
      {
        path: '/events',
        name: 'events',
        component: () => import(/* webpackChunkName: "events" */ '../views/events/index.vue')
      },
      // 活动详情
      {
        path: '/events-details',
        name: 'eventsDetails',
        component: () => import(/* webpackChunkName: "events-details" */ '../views/events/events-details.vue')
      },
      // 下载
      {
        path: '/download',
        name: 'download',
        component: () => import(/* webpackChunkName: "download" */ '../views/download/index.vue')
      },

      // 进入游戏详情
      {
        path: '/game-intro',
        name: 'gameIntro',
        component: () => import(/* webpackChunkName: "game-intro" */ '../views/game-intro/index.vue')
      },
      // 奖金列表
      {
        path: '/game-bonus',
        name: 'gameBonus',
        component: () => import(/* webpackChunkName: "game-bonus" */ '../views/game-bonus/index.vue')
      },
      // 奖金详情
      {
        path: '/bonus-details',
        name: 'bonusDetails',
        component: () => import(/* webpackChunkName: "bonus-details" */ '../views/game-bonus/bonus-details.vue')
      },
      // 页面详情 - 富文本
      {
        path: '/game-richtext',
        name: 'gameRichtext',
        component: () => import(/* webpackChunkName: "game-richtext" */ '../views/game-richtext/index.vue')
      },
      // 个人中心
      {
        path: '/userinfo',
        name: 'userinfo',
        component: () => import(/* webpackChunkName: "userinfo" */ '../views/userinfo/index.vue')
      },





      // 
      {
        path: '/record',
        name: 'record',
        component: () => import(/* webpackChunkName: "record" */ '../views/record/index.vue')
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/setting/index.vue')
      },

      {
        path: '/wallet',
        name: 'wallet',
        component: () => import(/* webpackChunkName: "wallet" */ '../views/wallet/index.vue')
      },
      {
        path: '/telegram',
        name: 'telegram',
        component: () => import(/* webpackChunkName: "telegram" */ '../views/telegram/index.vue')
      },
      {
        path: '/rewards',
        name: 'rewards',
        component: () => import(/* webpackChunkName: "rewards" */ '../views/rewards/index.vue')
      },
      // 通知消息
      {
        path: '/message',
        name: 'message',
        component: () => import(/* webpackChunkName: "message" */ '../views/message/index.vue')
      },


      // 合作伙伴
      {
        path: '/buddy',
        name: 'buddy',
        component: () => import(/* webpackChunkName: "buddy" */ '../views/buddy/index.vue')
      },


      // 公告
      {
        path: '/notice',
        name: 'notice',
        component: () => import(/* webpackChunkName: "notice" */ '../views/notice/index.vue')
      },
      // 投注记录
      {
        path: '/play-record',
        name: 'playRecord',
        component: () => import(/* webpackChunkName: "play-record" */ '../views/play-record/index.vue')
      },

      // 绑定
      {
        path: '/bind',
        name: 'bind',
        component: () => import(/* webpackChunkName: "bind" */ '../views/bind/index.vue')
      },

      // 所有游戏列表
      {
        path: '/all-game',
        name: 'allGame',
        component: () => import(/* webpackChunkName: "all-game" */ '../views/all-game/index.vue')
      },


    ]
  }


]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  // 如果未登录  跳转登录
  if(to.path == '/') return next()
  if(!isLogin()) return store.dispatch('setLoginDialog', true)
  next()
})

export default router
