import http from '../utils/request'

export const walletApi = {
    // 充值 提现
    withdrawRecharge: (data) => {
        return http({
            url: 'index/Withdraw_recharge',
            method: 'POST',
            data
        })
    },
    // 获取充值记录
    rechargeRecord: (data) => {
        return http({
            url: 'index/Wit_rech_list',
            method: 'POST',
            data
        })
    },
    // 获取充值选项
    rechlist: () => {
        return http({
            url: 'index/get_rechlist',
            method: 'POST',
        })
    },
    // 获取游戏金额
    gameAmount: () => {
        return http({
            url: 'index/get_wallet',
            method: 'GET',
        })
    },

    // 是否绑定
    isBind: () => {
        return http({
            url: 'index/is_bank',
            method: 'POST',
        })
    },

    // 绑定信息
    addBind: (data) => {
        return http({
            url: 'index/add_bank',
            method: 'POST',
            data
        })
    }
}
