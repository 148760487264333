
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// rem配置文件
import './utils/rem'

// 自动注册全局组件
import './requireComponent'

// 自动导入element组件
import element from './utils/auto-import'

// 工具类库
import Lodash from 'lodash' 

// 复制插件
import VueClipBoard from  'vue-clipboard2'

import { config } from './utils/config'
import { delay } from './utils'

import i18n from './i18n/index'

Vue.prototype.$lodash = Lodash
Vue.config.productionTip = false

Vue.prototype.$delay = delay
Vue.prototype.$config = config

// Vue.prototype.$setTemporizador = setInterval(() => {
// 	store.dispatch('getUserInfo')
// }, 3000)

Vue.use(element).use(VueClipBoard)

new Vue({
  el: '#app',
  i18n,
  router,
  store, 
  render: h => h(App)
}).$mount('#app')



































