<template>
  <div class="game_footer card_background game_border6">
    <div class="width1200">
      <div class="fo_nav game_flex">
        <div class="game_flex game_border_r game_align_center">
          <span>{{ userInfo.upmoeny || 0 }}</span>
          <span>{{ $t("footer.text1") }}</span>
        </div>
        <div class="game_flex game_border_r game_align_center">
          <span>{{ userInfo.gamenum || 0 }}</span>
          <span>{{ $t("footer.text2") }}</span>
        </div>
      </div>

      <div class="fo_list game_flex">
        <div class="li_fl game_flex game_flex1">
          <span style="color: #98abd0">{{ $t("footer.text10") }}</span>
          <span
            v-for="item in leftList"
            :key="item.gameCode"
            @click="enterGameBtn(item)"
            >
            {{ item.gameName }}
            </span>
        </div>
        <div class="li_con game_flex game_flex1">
          <span style="color: #98abd0">{{ $t("footer.text3") }}</span>
          <span
            v-for="item in fooList"
            :key="item.id"
            @click="$router.push({ path: `/game-richtext?id=${item.id}` })"
            style="cursor: pointer"
            >{{ item.title }}</span
          >
        </div>
        <div class="li_fr game_flex1">
          <div class="fo_share game_flex">
            <el-image
              v-for="(item, index) in shareList"
              :key="index"
              :src="item"
            ></el-image>
          </div>
          <div class="fo_introduce">
            <div>{{ $t("footer.text8") }}</div>
            <div>
              <div v-html="userDetails.content"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="fo_advertisement game_flex">
        <div v-for="(item, index) in advertisementList" :key="index">
          <el-image
            :showLoading="true"
            :src="item"
            width="100%"
            height="50rpx"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { homeApi } from "@/api/home";
export default {
  name: "gameFooter",
  computed: {
    ...mapGetters(["userInfo"]),
  },
  props: {
    leftList: {
      type: Array,
      default: () => {
        [];
      },
    },
  },
  data() {
    return {
      shareList: [
        require("@/assets/images/footer/i.png"),
        require("@/assets/images/footer/g.png"),
        require("@/assets/images/footer/f.png"),
        require("@/assets/images/footer/t.png"),
        require("@/assets/images/footer/i.png"),
        require("@/assets/images/footer/d.png"),
        require("@/assets/images/footer/w.png"),
      ],
      advertisementList: [
        require("@/assets/images/footer/google.png"),
        require("@/assets/images/footer/facebook.png"),
        require("@/assets/images/footer/caleta.png"),
        require("@/assets/images/footer/evoplay.png"),
        require("@/assets/images/footer/ezugi.png"),
        require("@/assets/images/footer/redtiger.png"),
        require("@/assets/images/footer/iron.png"),
        require("@/assets/images/footer/evolution.png"),
      ],
      userDetails: {},
      fooList: [], 
    };
  },
  created() {
    this.getFoolDetails();
    this.getCommonalityInstruc(); 
  },
  methods: {
    async getCommonalityInstruc() {
      // 8 = 关于我们
      let { code, data } = await homeApi.commonalityInstruc(8);
      if (code == 200) {
        this.userDetails = data;
      }
    },

    async getFoolDetails() {
      let { code, data } = await homeApi.foolDetails();
      if (code == 200) {
        this.fooList = data;
      }
    },

    // 进入游戏
    async enterGameBtn(row) {
      this.$router.push({ path: `/game-intro?id=${row.id}` });
    },
 
  },
};
</script>

<style lang="scss" scoped>
.game_footer {
  padding: 0.1042rem;
  width: 100%;

  .fo_nav {
    height: 0.7rem;
    justify-content: space-between;

    & > div {
      padding: 0.1042rem 0;
      width: 49%;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      font-size: 0.08rem;
      color: #fff;
      background-color: #353a44;

      & > span:nth-child(1) {
        font-size: 0.14rem;
        font-weight: 700;
        color: #437bde;
      }
    }
  }

  .fo_list {
    margin-top: 0.15rem;
    .li_fl {
      flex-direction: column;
      & > span {
        height: 0.18rem;
        font-size: 0.07rem;
        color: #58657c;
        cursor: pointer;
      }
    }

    .li_con {
      flex-direction: column;
      & > span {
        height: 0.18rem;
        font-size: 0.07rem;
        color: #58657c;
      }
    }
    .li_fr {
      .fo_share {
        margin: 10px 0;
        height: 0.1.6rem;
        .el-image {
          margin-right: 0.0521rem;
          width: 0.1563rem;
        }
      }
      .fo_introduce {
        font-size: 0.0729rem;
        line-height: 0.1042rem;
        color: #58657c;
      }
    }
  }
}
</style>