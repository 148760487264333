(function flexible(window, document) {
  var docEl = document.documentElement
  var dpr = window.devicePixelRatio || 1

  // set 1rem = viewWidth / 10
  function setRemUnit() {
    var rem = docEl.clientWidth / 10
    docEl.style.fontSize = (rem <= 100 ? '100px' : rem + 'px');
  }
  setRemUnit()

  // reset rem unit on page resize
  window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', function (e) {
    if (e.persisted) {
      setRemUnit()
    }
  })

  // detect 0.5px supports
  if (dpr >= 2) {
    var fakeBody = document.createElement('body')
    var testElement = document.createElement('div')
    testElement.style.border = '.5px solid transparent'
    fakeBody.appendChild(testElement)
    docEl.appendChild(fakeBody)
    if (testElement.offsetHeight === 1) {
      docEl.classList.add('hairlines')
    }
    docEl.removeChild(fakeBody)
  }
}(window, document))


// // 计算并设置根元素的字体大小
// function setFontSize() {
//   var screenWidth = document.documentElement.clientWidth; // 获取窗口宽度
//   var baseFontSize = 192; // 设置基准字体大小
//   var designWidth = 1920; // 设计稿的宽度

//   // 计算并设置根元素的字体大小
//   var scale = screenWidth / designWidth; // 计算缩放比例
//   var fontSize = baseFontSize * scale; // 计算字体大小
//   document.documentElement.style.fontSize = fontSize + 'px';
// }

// // 在窗口大小改变时重新计算字体大小
// window.addEventListener('resize', setFontSize);

// // 在页面加载完毕时首次计算字体大小
// window.addEventListener('DOMContentLoaded', setFontSize);