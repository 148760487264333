<template>
  <div class="game_index">
    <!-- 公告 -->
    <div class="game_notice game_flex">
      <el-image
        style="width: 0.1302rem; height: 0.1302rem"
        :src="require('@/assets/images/jogos/marquee.png')"
      ></el-image>
      <el-carousel
        :interval="2000"
        height="30px"
        direction="vertical"
        indicator-position="none"
      >
        <el-carousel-item v-for="item in noticeList" :key="item">
          <div style="color: #f9ae3d">{{ item }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="d_j_banner">
      <el-carousel :interval="3000" type="card" height="200px">
        <el-carousel-item v-for="item in bannerList" :key="item.name">
          <el-image
            class="game_border_r"
            style="width: 100%; height: 100%"
            :src="item.image"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="con_search game_border6">
      <div class="search_con game_flex">
        <div class="list game_flex">
          <div
            class="rows game_flex game_align_center game_content_center"
            :class="item.status == navItemStatus ? 'curr_rows' : ''"
            v-for="item in gameNavList"
            :key="item.status"
            @click="changeNavItem(item.status)"
          >
            <el-image
              style="width: 0.2083rem; height: 0.2083rem"
              :src="item.status == navItemStatus ? item.currUrl : item.url"
            ></el-image>
          </div>
        </div>
        <!-- <div class="search">
          <template v-if="showSearch">
            <el-input
              slot="reference"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="searchForm.keyword"
              @blur="searchForm.keyword = ''"
            >
            </el-input>
          </template>
          <template v-else>
            <el-button
              type="primary"
              icon="el-icon-search"
              circle
              @click="showSearch = true"
            ></el-button>
          </template>
        </div> -->
      </div>
    </div>

    <div class="show_search" v-if="navItemStatus != 1 && navItemStatus != 4">
      <el-input
        slot="reference"
        :placeholder="$t('jogos.text15')"
        prefix-icon="el-icon-search"
        v-model="searchForm.keyword"
        @blur="searchForm.keyword = ''"
      >
      </el-input>
    </div>

    <template v-if="!showSearchList">
      <div
        class="con_game_list card_background game_border6"
        v-for="(key, value) in gameList"
        :key="value"
      >
        <div class="item">
          <div class="top game_flex game_align_center">
            <el-image
              style="width: 0.2083rem; height: 0.2083rem"
              :src="key.img"
            ></el-image>
            <span>{{ value }}</span>
            <span
              @click="
                $router.push({
                  path: `/all-game?gameType=${key.data[0].gameType}&platType=${key.data[0].platType}`,
                })
              "
              >Todos</span
            >
          </div>
          <div class="list game_flex">
            <div
              class="li_item game_hover_style"
              v-for="item in key.data"
              :key="item.id"
              @click="enterGameBtn(item)"
            >
              <div class="row">
                <el-image
                  class="game_border6"
                  style="width: 100%; height: 1.1rem"
                  :src="item.imageUrl"
                ></el-image>

                <div>{{ item.gameName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <!-- 搜索后展示 -->
      <div class="con_search_list game_flex card_background game_border6">
        <div
          class="li_item game_hover_style"
          @click="enterGameBtn(item)"
          v-for="item in searchGameList"
          :key="item.id"
        >
          <div class="row">
            <el-image
              class="game_border6"
              style="width: 100%; height: 0.9rem"
              :src="item.imageUrl"
            ></el-image>

            <div>
              {{ item.gameName }}
            </div>
          </div>
        </div>
        <el-empty class="el_empty"  v-show="searchGameList.length == 0" :image-size="60"></el-empty>
      </div>
    </template>


    <div class="d_kefu" @click="openCall">
      <el-image
              class="game_border6"
              style="width: 100%; height: 100%"
              :src="require('@/assets/images/jogos/dianban1.png')"
            ></el-image>
		</div>
  </div>
</template>
  
  <script>
import { homeApi } from "@/api/home.js";
export default {
  name: "GameIndex",
  created() {
    this.getBameList();
    this.getBanner();
    this.getNoticeList();
  },
  data() {
    return {
      dialogVisible: true,
      navItemStatus: 1,
      bannerList: [],
      noticeList: [],
      gameNavList: [
        {
          url: require("@/assets/images/jogos/paging-all.png"),
          currUrl: require("@/assets/images/jogos/paging-all1.png"),
          status: 1,
        },

        {
          url: require("@/assets/images/jogos/paging-hot.png"),
          currUrl: require("@/assets/images/jogos/paging-hot1.png"),
          status: 3,
        },
        {
          url: require("@/assets/images/jogos/paging-favorite.png"),
          currUrl: require("@/assets/images/jogos/paging-favorite1.png"),
          status: 4,
        },
        {
          url: require("@/assets/images/jogos/paging-pg.png"),
          currUrl: require("@/assets/images/jogos/paging-pg1.png"),
          status: 5,
        },
        {
          url: require("@/assets/images/jogos/paging-evo.png"),
          currUrl: require("@/assets/images/jogos/paging-evo1.png"),
          status: 6,
        },
      ],
      loginForm: {
        name: "",
      },
      loginRules: {
        name: [{ required: true, message: "请输入账号", trigger: "blur" }],
      },
      gameList: [],
      showSearch: false,
      searchForm: {
        keyword: "",
        page: 1,
        list: 10,
      },
      searchGameList: [],
      total: 0,
      showSearchList: false,
    };
  },
  methods: {
    loginSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(valid);
        }
      });
    },
    loginReset() {},

    changeNavItem(status) {
      this.searchGameList = [],
      this.showSearchList = false,
      this.navItemStatus = status; 
      // 收藏列表
      if (status == 4) return this.getGame();
      this.getBameList();
    },

    async getBameList() {
      let { data, code } = await homeApi.gameList({
        status: this.navItemStatus,
      });
      if (code == 200) {
        this.gameList = data;
      }
    },

    // 进入游戏
    async enterGameBtn(row) {
      this.$router.push({ path: `/game-intro?id=${row.id}` });
    },
    // 模糊搜索
    async getFuzzyQueryGame() {
      this.showSearchList = true;

      let { code, data } = await homeApi.fuzzyQueryGame(this.searchForm);
      if (code == 200) {
        this.total = data.tal;
        this.searchGameList = data.data;
      }
    },

    async getBanner() {
      let { data, code } = await homeApi.banner();
      if (code == 200) {
        data.map((item) => {
          return (item.image = this.$config.baseImgUrl + item.image);
        });

        console.log(data)
        this.bannerList = data;
      }
    },

    // 获取首页轮播消息
    async getNoticeList() {
      let { code, data } = await homeApi.notice();
      if (code == 200) {
        this.noticeList = data;
      }
    },

    // 获取收藏游戏列表
    async getGame() {
      let { code, data } = await homeApi.getGame();
      if (code == 200) {
        this.searchGameList = data;
        this.showSearchList = true;
      }
    },

    openCall() {
      window.location.href = 'https://t.me/cokejogo'
			}
  },

  watch: {
    "searchForm.keyword"() {
      this.searchGameList = [];
      this.total = 0;
      this.searchForm.page = 1;
      this.searchForm.list = 10;

      if (this.searchForm.keyword) {
        this.getFuzzyQueryGame();
      } else {
        this.showSearch = false;
        this.showSearchList = false;
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.game_index {
  .game_notice {
    .el-carousel {
      margin-left: 10px;
      width: 100%;
    }
  }
  .con_search {
    margin: 0.1042rem 0;
    padding: 0.0417rem 0.0833rem;
    min-height: 52px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.2083rem;
    background: linear-gradient(180deg, #2e3245 0%, #1f2332 100%);
    .search_con {
      height: 0.3125rem;
      .list {
        height: 100%;
        flex: 1;
        .rows {
          margin-right: 0.1042rem;
          width: 0.9375rem;
          cursor: pointer;
          font-size: 0.0938rem;
          border-radius: 0.5208rem;
          & > span {
            margin-left: 0.1563rem;
          }
          &:hover {
            background: #2e3141;
          }
        }
        .curr_rows {
          background: linear-gradient(
            180deg,
            #4cc4ff 0%,
            #256eff 100%
          ) !important;
        }
      }

  
    }
  }
  .con_game_list {
    margin-bottom: 0.1042rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(180deg, #2e3245 0%, #1f2332 100%);
    .item {
      padding: 0.1042rem 0.1563rem;
      .top {
        height: 0.2604rem;
        & > span:nth-child(2) {
          flex: 1;
          margin-left: 0.1563rem;
          font-weight: 700;
        }
        & > span:last-child {
          cursor: pointer;
        }
      }
      .list {
        margin-top: 0.1042rem;
        flex-wrap: wrap;
        .li_item {
          padding: 0.0521rem 0.1042rem;
          height: 1.5rem;
          flex-basis: calc(100% / 6);
          cursor: pointer;
          .row {
            height: 100%;
            & > div:last-child {
              padding-top: 0.0521rem;
            }
          }
        }
      }
    }
  }

  .con_search_list {
    margin-top: 0.1042rem;
    flex-wrap: wrap;
    min-height: 3.125rem;
    .li_item {
      padding: 0.0521rem 0.1042rem;
      height: 1.25rem;
      flex-basis: calc(100% / 7);
      cursor: pointer;
      .row {
        height: 100%;
        & > div:last-child {
          padding-top: 0.0521rem;
        }
      }
    }
  }
  .show_search{
    margin: .0521rem;
  }

  .d_kefu {
			position: fixed;
			bottom: .5208rem;
			right: .2083rem;
      height: .3125rem;
      width: .3125rem;
			border-radius: 50%;
		}
}
</style>