<template>
  <div class="game_language">
    <div
      class="lan_item game_flex game_align_center game_content_center"
      v-for="item in languageList"
      :key="item.id"
      @click="confirmLanguage(item)"
    >
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "gameLanguage",
  data() {
    return {
      languageList: [
        // { label: "中文", id: "zh" },
        { label: "english", id: "english" },
        { label: "spain", id: "spain" },
        { label: "portugal", id: "portugal" },
      ],
    };
  },
  methods: {
    confirmLanguage({ id }) {
      this.$store.dispatch("setLanguage", id).then(() => {
        this.$i18n.locale = id
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.game_language {
  .lan_item {
    height: 40px;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
}
</style>