
<template>
  <div class="game_announcement">
    <el-dialog
      :visible.sync="showAnnouncementDialog"
      width="480px"
      top="10vh"
      custom-class="no-header-dialog"
      :show-close="false"
      @close="$store.dispatch('setAnnouncementDialog', false)"
    >
      <div class="d_border12 an_info">
        <div v-html="content[index] && content[index].content"></div>
      </div>

      <div class="vip_five game_flex game_content_center">
        <div class="game_flex d_border12 game_align_center game_content_center">
          <i
            class="el-icon-caret-left"
            :style="{ color: index == 0 ? '#999' : '#fff' }"
            @click="previous"
          ></i>
        </div>
        <div class="game_flex d_border12 game_align_center game_content_center">
          <i
            class="el-icon-caret-right"
            :style="{ color: index == this.content.length - 1 ? '#999' : '#fff' }"
            @click="nextPage"
          ></i>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "gameAnnouncement",
  computed: {
    ...mapGetters(["showAnnouncementDialog"]),
  },
  props: {
    content: {
      type: Array,
      default: () => {
        [];
      },
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  methods: {
    // 上一页
    previous() {
      if (this.index > 0) this.index -= 1;
    },

    // 下一页
    nextPage() {
      if (this.index < this.content.length - 1) this.index += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.game_announcement {
    .an_info{
        overflow: hidden;
        width: 100%;
        height: 500px;
        overflow-y: auto;
        img { 
            width: 100%;
        }
    }
    .vip_five{
            padding: 10px 0 10px 0;
			background-color: #1c1b32;
			& > div {
				margin-left: 20px;
				width: 50px;
				height: 50px;
				background-color: #202a38;
				cursor: pointer;
			}
		}

    ::v-deep img {
        width: 100%;
    }
}
</style>