export default {
	jogos: {
		text1: 'Recomendado',
		text2: 'Gana dinero fácilmente',
		text3: 'Mina',
		text4: 'Colisión',
		text5: 'Borde del territorio',
		text6: 'Dado',
		text7: 'Doble',
		text8: 'Doble fila',
		text9: 'Todos los juegos',
		text10: 'Código de cupón',
		text11: 'Bono de primer depósito',
		text12: 'Club VIP',
		text13: 'Ver más ofertas',
		text14: 'Todo',
		text15: 'Por favor ingrese',
		text16: 'Apuesta',
		text17: 'Notificación',
		text18: 'Depósito',
		text19: 'Retirada',
		text20: 'Nivel VIP',
		text21: 'Cuenta',
		text22: 'Registro',
		text23: 'Salir',
		text24: 'Inicio de sesión',
		text25: 'Registro',
		text27: 'Guardar',
		text28: 'Quitar de favoritos'
	},
	footer: {
		text1: 'Jogador',
		text2: 'Vencedor',
		text3: 'Ayuda',
		text4: 'Política de KYC',
		text5: 'Política de privacidad',
		text6: 'Términos y condiciones',
		text7: 'Preguntas frecuentes',
		text8: 'Sobre nosotros',
		text9: 'Este sitio ofrece juegos con experiencia de riesgo. Para convertirse en nuestro usuario, debe tener al menos 18 años. No asumimos ninguna responsabilidad por cualquier violación de las leyes locales relacionadas con los juegos en línea. Juegue responsablemente y disfrute en bbrjogo | Casino en línea, Juegos de casino en línea con dinero real.',
		text10: 'Juegos'
	},
	tabbar: {
		text1: 'Página de inicio',
		text2: 'Compartir',
		text4: 'Actividad',
		text5: 'Aplicación',
	},

	bonus: {
		text1: 'Promociones',
		text2: 'Descubrir más'
	},
	download: {
		text1: 'COKE JOGO | Juegos de casino en línea con dinero real',
	},

	convidar: {
		text1: 'Invitar',
		text2: 'Datos estadísticos',
		text3: 'Invitado',
		text4: 'Bonificación',
		text5: 'Detalles de la invitación',
		text6: 'Total de personas',
		text7: 'Ingresos totales',
		text8: 'Total de personas hoy',
		text9: 'Ingresos de hoy',
		text10: 'Comisión/mes',
		text11: 'URL para convertir',
		text12: 'Copiar enlace',
		text13: 'Invita a amigos, junta fortunas',
		text14: '¿Quieres ganar más ingresos fácilmente? ¿Quieres que tus amigos encuentren el camino a la riqueza? Únete a nosotros, ¡luchemos juntos!',


		text15: 'Resumen',
		text16: 'Nivel',
		text17: 'Grado',
		text18: 'Número de personas',
		text19: 'Primer depósito',
		text20: 'Recarga total',
		text21: 'Total retirado',
		text22: 'Beneficio total'
	},

	userinfo: {
		text1: 'Gestión de saldo',
		text2: 'Registro',
		text3: 'Invitar a amigos',
		text4: 'Equilibrio',
		text5: 'Mi nivel VIP',
		text6: 'Apuesta',
		text7: 'Todos los niveles',
		text8: 'Retiro',
		text9: 'Retirable',
		text10: 'Desbloquear su saldo Apuesta para liberar',
		text11: 'Apuesta',
		text12: 'Recarga',
		text13: 'Flujo de efectivo',
		text14: 'Reembolso',
		text15: 'Cancelar',
		text16: 'Confirmar',
		text17: 'Fecha de inicio',
		text18: 'Fecha de finalización',
		text19: 'No hay datos',
		text20: 'Notas',
		text21: 'Nombre del juego',
		text22: 'Cantidad de apuesta',
		text23: 'Ganancias y pérdidas',
		text24: 'Hora de creación',
		text25: 'Cantidad de recarga',
		text26: 'Cantidad de retirada',
		text27: 'Estado',
		text28: 'Tiempo',
		text29: 'Número de pedido',
		text30: 'Cantidad objetivo de codificación',
		text31: 'Actualmente completado',
		text32: 'Eliminación de',
		text33: 'Detalles de la invitación',
		text34: 'Usuario'
	},

	vip: {
		text1: 'La elegibilidad puede desbloquearse acumulando',
		text2: 'Cantidad y',
		text3: 'Apuesta válida. Obtén descuentos en los juegos',
		text4: 'Cuanto mayor sea el nivel, mayor será el descuento',
		text5: 'VIP',
		text6: 'Requisitos de nivel',
		text7: 'Debe alcanzar el flujo de efectivo',
		text8: 'Debe alcanzar la cantidad de recarga',
		text9: 'Derechos de nivel',
		text10: 'Manejo de retiro',
		text11: 'Número de retiros diarios',
		text12: 'Límite diario de retiro',
		text13: 'Recompensa semanal',
		text14: 'Recompensa mensual',
		text15: 'Recompensa de mejora de miembro',
		text16: 'Límite de retiro por transacción',
	},
	login: {
		title1: 'Introduce el correo electrónico',
		title2: 'Introduce la contraseña',
		title3: 'Olvidé mi contraseña',
		title4: 'Iniciar sesión',
		title5: 'Código de verificación',

		title6: 'Siguiente',
		title7: 'Código de invitación',
		text1: 'Obtener Código de Verificación',
		text2: 'Obtener de Nuevo',
		text3: 'Segundos para Obtener de Nuevo',
		text4: 'Código de Verificación Enviado',

		text5: 'Nombre de usuario es obligatorio',
		text6: 'Por favor, introduce un correo electrónico válido',
		text7: 'La contraseña es obligatoria',
		text8: 'La contraseña debe tener entre 6 y 20 caracteres',
		text9: 'La longitud del código de verificación debe ser de 4 a 6 caracteres',
		text10: 'Recuperar contraseña',
		text11: 'El código de verificación es obligatorio',
		text12: 'Segundos más tarde recuperar',
		text13: 'Contraseña',
		text14: 'Nombre de usuario'
	},

	account: {
		text1: 'Depósito',
		text2: 'Retirada',
		text3: 'Límite',
		text4: 'Por favor ingrese',
		text5: 'No participar en promociones',
		text6: 'Retirar un mensaje',
		text7: 'Problemas de pago',
		text8: 'Haga clic para ayuda',
		text9: 'Boleto de depósito',
		text10: 'Requisito de depósito mínimo',
		text11: 'Ingrese su mensaje de depósito correcto',
		text12: 'El dinero llega a la cuenta en 5 minutos como máximo',
		text13: 'Si tiene alguna pregunta, no dude en contactarnos.',
		text14: 'Saldo disponible',
		text15: 'Cantidad',
		text16: 'Retirar a',
		text17: '',
		text18: 'Boleto de retirada',
		text19: 'Nivel',
		text20: 'Veces de retirada',
		text21: 'Tasa de retiro',
		text22: 'Cantidad mínima de retiro',
		text23: 'Monto máximo de retiro',
		text24: 'Tiempo abierto',
		text25: 'El tiempo de retiro más rápido es de 10 minutos.',
		text26: 'Todos los campos deben completarse. Los datos deben ser reales y verificarse antes de la retirada. Si los datos ingresados no son correctos, no recibirá el dinero y su saldo tampoco se devolverá. ¡Ten cuidado al llenar este formulario!',
		text27: 'Si tiene alguna pregunta, no dude en contactarnos.',
		text28: 'Apellido',
		text29: 'Nombre',
		text30: 'Correo electrónico',
		text31: 'Teléfono',
		text32: 'Número de identificación fiscal',
		text33: 'Cuenta',
		text34: 'Por favor seleccione',
		text35: 'Guardar'
	},

	msg: {
		text1: 'Formato de correo electrónico incorrecto',
		text2: 'Registro exitoso',
		text3: 'Inicio de sesión exitoso',
	},

	coupon: {
		text1: 'convertible',
		text2: 'Recordatorio de cupones',
		text3: 'Asegúrese de que su nombre y tarjeta bancaria sean exactos y únicos. Si el mismo usuario registra más de una cuenta con diferentes ips de red y obtiene un código promocional, se considerará fraude y su cuenta será permanentemente congelada.',
		text4: 'Intercambio realizado con éxito'
	},
	mine: {
		text1: 'Saldo de la cuenta',
		text2: 'Monto del juego',
		text3: 'Total de depósitos',
		text4: 'Puntos de apuesta',
		text5: 'Deposito',
		text6: 'Cuenta',
		text7: 'Otras funciones',
		text8: 'Registro de facturas',
		text9: 'Conversión de cuota',
		text10: 'Registro de partidos',
		text11: 'Ajustes',
		text12: 'Desconectar',
		text13: 'Seleccionar',
		text14: 'Transferir',
		text15: 'Retirar',
		text16: 'Saldo disponible',
		text17: 'Cantidad de la transferencia',
		text18: 'Cantidad de retiro',
		text19: 'Ingrese la cantidad',
		text20: 'Transferir a la billetera',
		text21: 'Transferir al saldo',
		text22: 'Transferir el juego al saldo',
		text23: 'Cuenta actual',
		text24: 'Enviar',
		text25: 'Éxito de operación',
		text26: 'Borrar todo leído',
		text27: 'Replicar el éxito'
	},
	paycard: {
	    title1: 'Apellido',
	    title2: 'Nombre',
	    title3: 'Correo Electrónico',
	    title4: 'Número de Teléfono',
	    title5: 'ID Fiscal',
	    title6: 'Por favor ingrese',
	    title7: 'Cuenta',
	    title8: 'Tipo de Cuenta',
	    title9: 'Formato inválido',
	    title10: 'Por favor seleccione'
	}
}