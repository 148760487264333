<template>
  <div class="game_tabbar game_flex">
    <div
      class="tabbar_item game_flex1 game_flex game_align_center"
      v-for="(item, i) in defaultTabbar"
      :key="item.text"
      @click="changePath(item, i)"
    >
      <el-image
        :class="[i == 1 && 'rotare', i == 2 ? 'con_tab' : '']"
        :src="i == activeIndex ? item.selectedIconPath : item.iconPath"
      ></el-image>

      <!-- <el-image
        :class="[i == 1 && 'rotare']"
        :src="item.selectedIconPath"
      ></el-image> -->
      <!-- <el-image :class="[i == 1 && 'rotare']" slot="inactive-icon" :src="item.iconPath"
					:size="i == 2? '68': '28'"></el-image> -->
      <span :style="{ color: i == activeIndex ? '#f4729a' : '#999' }">{{
        item.text
      }}</span>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "gameTabbar",
  data() {
    return {
      defaultTabbar: [
        {
          pagePath: "/",
          iconPath: require("@/assets/images/tabbar/game-unchecked.png"),
          selectedIconPath: require("@/assets/images/tabbar/game-checked.png"),
          text: this.$t("tabbar.text1"),
        },
        {
          pagePath: "/convidar",
          iconPath: require("@/assets/images/tabbar/share-unchecked.png"),
          selectedIconPath: require("@/assets/images/tabbar/share-checked.png"),
          text: this.$t("tabbar.text2"),
        },
        {
          iconPath: require("@/assets/images/tabbar/wallet.gif"),
          selectedIconPath: require("@/assets/images/tabbar/wallet.gif"),
          // text: "Depósito",
        },
        {
          pagePath: "/game-bonus",
          iconPath: require("@/assets/images/tabbar/bonus-unchecked.png"),
          selectedIconPath: require("@/assets/images/tabbar/bonus-checked.png"),
          text: this.$t("tabbar.text4"),
        },
        {
          pagePath: "/download",
          iconPath: require("@/assets/images/tabbar/download-unchecked.png"),
          selectedIconPath: require("@/assets/images/tabbar/download-checked.png"),
          text: this.$t("tabbar.text5"),
        },
      ],
      activeIndex: 0,
    };
  },
  methods: {
    changePath(row, i) {
      this.activeIndex = i;
      if (i === 2) {
        this.$store.dispatch("setAccountDialog", true);
        return;
      }
      this.$router.push({ path: row.pagePath });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.game_tabbar {
  position: fixed;
  bottom: 0;
  height: 0.3646rem;
  width: 100%;
  background: #212330;
  .tabbar_item {
    flex-direction: column;
    cursor: pointer;
    justify-content: space-around;
    font-size: 0.0729rem;
    .el-image {
      width: 0.1823rem;
      flex-shrink: 0;
    }
    .rotare {
      animation: spin 3s linear infinite;
    }

    .con_tab {
      position: relative;
      top: -0.16rem;
      width: .4688rem;
      height: .4688rem;
    }
  }
}

@keyframes grape {
  0% {
    transform: scale(1);
  }

  10% {
    transform: translate3d(-1px, 0, 0);
  }

  70% {
    box-shadow: 0 0 0 10px transparent;
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 transparent;
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>