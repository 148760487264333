<template>
  <div class="game_dialog">
    <el-dialog
      :visible.sync="showLoginDialog"
      width="380px"
      top="20vh"
      custom-class="no-header-dialog"
      :show-close="false"
      @close="$store.dispatch('setLoginDialog', false)"
    >
      <div class="login_dialog">
        <div class="login_type game_flex game_align_center">
          <div
            :class="['row_type', loginNavIndex == item.value && 'active_type']"
            v-for="item in loginnav"
            :key="item.value"
            @click="changeLoginNavIndex(item.value)"
          >
            <span>{{ item.label }}</span>
          </div>

          <div
            class="close game_flex1"
            @click="$store.dispatch('setLoginDialog', false)"
          >
            <i class="el-icon-close"></i>
          </div>
        </div>

        <div class="login_from">
          <el-form :model="loginForm" :rules="loginRules" ref="loginRefs">
            <!-- 登录 -->
            <div v-if="loginNavIndex == 1">
              <el-form-item prop="account">
                <el-input
                  v-model="loginForm.account"
                  :placeholder="$t('login.title1')"
                >
                  <i slot="prefix" class="el-input__icon el-icon-message"></i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input
                  v-model="loginForm.password"
                  type="password"
                  :placeholder="$t('login.title2')"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                  <i slot="suffix" class="el-input__icon el-icon-view"></i>
                </el-input>
              </el-form-item>
            </div>

            <!-- 注册 -->
            <div v-if="loginNavIndex == 2">
              <el-form-item prop="account">
                <el-input
                  v-model="loginForm.account"
                  :placeholder="$t('login.title1')"
                >
                  <i slot="prefix" class="el-input__icon el-icon-message"></i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input
                  v-model="loginForm.password"
                  :placeholder="$t('login.title2')"
                  type="password"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                  <i slot="suffix" class="el-input__icon el-icon-view"></i>
                </el-input>
              </el-form-item>

              <el-form-item label="" prop="codes">
                <el-input
                  v-model="loginForm.codes"
                  :placeholder="$t('login.title5')"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>

                  <el-button
                    slot="suffix"
                    type="primary"
                    size="mini"
                    :disabled="disableButton"
                    @click="verificationMailbox('loginRefs', 'register')"
                    >{{ buttonText }}</el-button
                  >
                  <!-- <i slot="suffix" class="el-input__icon el-icon-view"></i> -->
                </el-input>
              </el-form-item>

              <el-form-item label="" >
                <el-input
                  v-model="loginForm.invitation_code"
                  :placeholder="$t('login.title7')"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
            </div>

            <el-form-item>
              <el-button type="text" @click="retrievePassword = true"
                >{{ $t("login.title3") }}?</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button
                class="login_btn"
                type="primary"
                round
                @click="loginSubmit('loginRefs')"
              >
                {{
                  loginNavIndex == 1 ? $t("jogos.text24") : $t("jogos.text25")
                }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="480px"
      top="20vh"
      :visible.sync="retrievePassword"
      append-to-body
      custom-class="child_dialog"
    >
      <div class="retrieve_dialog">
        <el-form
          :model="retrieveForm"
          :rules="retrieveRules"
          ref="retrieveForm"
          label-position="top"
        >
          <el-form-item prop="username">
            <el-input
              v-model="retrieveForm.username"
              :placeholder="$t('login.title1')"
            >
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
            </el-input>
          </el-form-item>

          <template v-if="retrieveProcedure == 1">
            <el-form-item
              :label="retrieveProcedure == 2 ? this.$t('login.text14') : ''"
              :key="retrieveProcedure"
            >
              <el-button
                class="next_btn"
                type="primary"
                round
                @click="verificationMailbox('retrieveForm', 'retrieve')"
              >
                {{ $t("login.title6") }}
              </el-button>
            </el-form-item>
          </template>

          <template v-if="retrieveProcedure == 2">
            <el-form-item
              :prop="retrieveProcedure == 2 ? 'codes' : ''"
              :label="$t('login.title5')"
            >
              <el-input
                v-model="retrieveForm.codes"
                :placeholder="$t('login.title7')"
              >
                <i slot="prefix" class="el-input__icon el-icon-message"></i>
              </el-input>
            </el-form-item>
            <el-form-item
              :prop="retrieveProcedure == 2 ? 'password' : ''"
              :label="$t('login.text13')"
            >
              <el-input
                v-model="retrieveForm.password"
                :placeholder="$t('login.title1')"
              >
                <i slot="prefix" class="el-input__icon el-icon-message"></i>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                class="retrieve_btn"
                type="primary"
                round
                @click="retrieveSubmit('retrieveForm')"
              >
                {{ $t("login.text10") }}
              </el-button>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { homeApi } from "@/api/home";

export default {
  name: "GameDialog",
  computed: {
    ...mapGetters(["showLoginDialog", "loginNavIndex"]),
  },
  data() {
    return {
      loginnav: [
        { label: this.$t("jogos.text24"), value: 1 },
        { label: this.$t("jogos.text25"), value: 2 },
      ],
      loginForm: {
        account: "",
        password: "",
        invitation_code: "",
        codes: "",
      },
      loginRules: {
        account: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.text7"),
            trigger: "change",
          },
          { min: 6, max: 20, message: this.$t("login.text8"), trigger: "blur" },
        ],
        codes: [
          {
            required: true,
            message: this.$t("login.text11"),
            trigger: "change",
          },
          { min: 4, max: 6, message: this.$t("login.text9"), trigger: "blur" },
        ],
        // invitation_code: [
        //   {
        //     required: true,
        //     message: this.$t("login.text11"),
        //     trigger: "change",
        //   },
        //   { min: 4, max: 6, message: this.$t("login.text9"), trigger: "blur" },
        // ],
      },

      retrievePassword: false,

      retrieveForm: {
        username: "",
        codes: "",
        password: "",
      },
      retrieveProcedure: 1,
      retrieveRules: {
        username: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
        codes: [
          {
            required: true,
            message: this.$t("login.text11"),
            trigger: "change",
          },
          { min: 4, max: 6, message: this.$t("login.text9"), trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.text7"),
            trigger: "change",
          },
          { min: 6, max: 20, message: this.$t("login.text8"), trigger: "blur" },
        ],
      },

      disableButton: false,
      countDownNumber: 60,
      buttonText: this.$t("login.text7"),
    };
  },
  methods: {
    async loginSubmit(formName) {
      try {
        await this.$refs[formName].validate();
        let data;

        if (this.loginNavIndex === 1) {
          // 登录
          data = await homeApi.login(this.loginForm);
          if (data.code == 200) {
            this.$store.dispatch("setUserInfo", data.data.userinfo);
            this.$store.dispatch("setLoginDialog", false);
            this.$setTemporizador()
          }
        } else {
          // 注册
          data = await homeApi.login(this.loginForm);
          if (data.code == 200) {
            this.$notification.success(this.$t("msg.text2"));
            this.$store.dispatch("setLoginNavIndex", 1);
          }
        }
      } catch (error) {
        console.log("error submit!!", error);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 切换方式
    changeLoginNavIndex(index) {
      this.$store.dispatch("setLoginNavIndex", index);

      this.$refs.loginRefs.resetFields();
    },

    // 验证邮箱
    verificationMailbox(formName, event) {
      // this.retrieveProcedure = 2;
      // event register 注册 、 retrieve 找回密码
      // 注册时发送邮件
      if (event == "register") {
        this.$refs[formName].validateField("account", async (val) => {
          if (!val) {
            this.getVerificationCode();
            let form = {
              username: this.loginForm.account,
              event,
            };
            let ins = await homeApi.verificationCode(form);
            if (ins.code == 200) {
              this.$notification.success(this.$t("msg.text2"));
            }
          }
        });
      }

      if (event == "retrieve") {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let form = {
              username: this.retrieveForm.username,
              event,
            };
            let ins = await homeApi.verificationCode(form);
            if (ins.code == 200) {
              this.retrieveProcedure = 2;
            }
          }
        });
      }

      // 找回密码时发送邮件

      // this.$refs[formName].validate(async (valid) => {
      //   if (valid) {
      //     let form = {
      //       username: this.retrieveForm.username,
      //       event
      //     };
      //     let ins = await homeApi.verificationCode(form);
      //     if (ins.code == 200) {
      //       this.retrieveProcedure = 2;
      //     }
      //   }
      // });
    },

    // 找回密码
    retrieveSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let ins = await homeApi.forgotPassword(this.retrieveForm);
          if (ins.code == 200) {
            this.retrievePassword = false;
          }
        }
      });
    },
    getVerificationCode() {
      this.disableButton = true;
      let counter = setInterval(() => {
        if (this.countDownNumber > 0) {
          this.countDownNumber -= 1;
          this.buttonText = this.countDownNumber + this.$t("login.text12");
        } else {
          clearInterval(counter);
          this.buttonText = this.$t("login.text7");
          this.disableButton = false;
          this.countDownNumber = 60;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.game_dialog {
  .login_dialog {
    color: #fff;

    .login_type {
      margin-bottom: 20px;
      transform: skew(15deg);

      .row_type {
        padding: 10px 0;
        width: 100px;
        color: #d7d7d7;
        text-align: center;
        cursor: pointer;

        & > span {
          display: inline-block;
          transform: skew(-15deg);
        }
      }

      .close {
        transform: skew(-15deg);
        text-align: right;
        cursor: pointer;
      }

      .active_type {
        background-color: #2282f5;
        font-weight: 700;
      }
    }

    .login_from {
      .fr_one {
        margin: 20px 0;
        flex-direction: column;
        align-items: center;
        font-size: 34px;

        & > span {
          margin-top: 20px;
        }
      }

      .fr_four {
        height: 80px;
        line-height: 80px;
      }

      .esqueceu {
        height: 70px;
        line-height: 70px;
        color: #61adfd;
        text-decoration: underline;
      }
    }

    .off_icon {
      position: absolute;
      top: 40px;
      right: 6%;
    }

    .login_btn {
      width: 300px;
    }
  }
}
::v-deep .child_dialog {
  background: #141922;
  border-radius: 10px;
  .el-dialog__header {
    display: none;
  }
  .el-input__inner {
    color: #fff;
    border-radius: 6px;
    border: none;
    background: #2b2e3d;
  }

  .next_btn {
    margin-top: 20px;
    width: 100%;
  }

  .retrieve_btn {
    width: 100%;
  }
}
</style>

 