export default {
	jogos: {
		text1: 'Recommend',
		text2: 'Make money easily',
		text3: 'Mine',
		text4: 'Collision',
		text5: 'Edge of the region',
		text6: 'Dice',
		text7: 'Double',
		text8: 'Double row',
		text9: 'All games',
		text10: 'Coupon code',
		text11: 'First deposit bonus',
		text12: 'VIP Club',
		text13: 'View more promotions',
		text14: 'All',
		text15: 'Please enter',
		text16: 'Bet',
		text17: 'Notification',
		text18: 'Deposit',
		text19: 'Withdraw',
		text20: 'VIP level',
		text21: 'Account',
		text22: 'Record',
		text23: 'Exit',
		text24: 'Sign in',
		text25: 'Register',
		text26: 'Language',
		text27: 'Collect',
		text28: 'Unfavorite'
	},
	footer: {
		text1: 'Jogador',
		text2: 'Vencedor',
		text3: 'Help',
		text4: 'KYC Policy',
		text5: 'Privacy Policy',
		text6: 'Terms and Conditions',
		text7: 'FAQ',
		text8: 'About Us',
		text9: 'This website offers games with risks involved. To become a user of our site, you must be 18 years old. We take no responsibility for any actions that breach your local laws on online gambling. Play responsibly and enjoy fun at online casino at bbrjogo. Win real money on online casino games.',
		text10: 'Game'
	},
	tabbar: {
		text1: 'Home',
		text2: 'Share',
		text4: 'Activity',
		text5: 'App',
	},
	bonus: {
		text1: 'Promotions',
		text2: 'Learn more'
	},
	download: {
		text1: 'COKE JOGO | Online casino win money online casino games',
	},
	convidar: {
		text1: 'Invite',
		text2: 'Statistics',
		text3: 'Invitees',
		text4: 'Rewards',
		text5: 'Invitation Details',
		text6: 'Total Number',
		text7: 'Total Revenue',
		text8: 'Total Number Today',
		text9: "Today's Revenue",
		text10: 'Commission/Month',
		text11: 'URL Conversion',
		text12: 'Copy Link',
		text13: 'Invite Friends, Gather Wealth Together',
		text14: "Want to easily earn more income? Want to help your friends find the path to riches? Join us and let's work together!",

		text15: 'Summary',
		text16: 'Level',
		text17: 'Grade',
		text18: 'Number of people',
		text19: 'First deposit',
		text20: 'Total recharge',
		text21: 'Total withdrawal',
		text22: 'Total profit'
	},
	userinfo: {
		text1: 'Balance Management',
		text2: 'History',
		text3: 'Invite Friends',
		text4: 'Balance',
		text5: 'My VIP Level',
		text6: 'Bet',
		text7: 'All Levels',
		text8: 'Withdraw',
		text9: 'Available for Withdrawal',
		text10: 'Unlock Your Balance by Betting',
		text11: 'Bet',
		text12: 'Deposit',
		text13: 'Amount of Money Involved',
		text14: 'Rebate',
		text15: 'Cancel',
		text16: 'Confirm',
		text17: 'Start Time',
		text18: 'End Time',
		text19: 'No Data',
		text20: 'Remarks',
		text21: 'Game Name',
		text22: 'Bet Amount',
		text23: 'Win/Loss Amount',
		text24: 'Creation Time',
		text25: 'Deposit Amount',
		text26: 'Withdrawal Amount',
		text27: 'Status',
		text28: 'Time',
		text29: 'Order Number',
		text30: 'Target Coding Amount',
		text31: 'Current Completed',
		text32: 'remove',
		text33: 'Invitation details',
		text34: 'User'
	},
	vip: {
		text1: 'Qualification can be unlocked by accumulative',
		text2: 'Amount and',
		text3: 'Effective bet. Get high game discount',
		text4: 'The higher the level, the bigger the discount',
		text5: 'VIP',
		text6: 'Level requirements',
		text7: 'Need to reach the water',
		text8: 'Need to reach the recharge amount',
		text9: 'Level rights',
		text10: 'Withdrawal fee',
		text11: 'daily withdrawal times',
		text12: 'Withdrawal daily limit',
		text13: 'Weekly reward',
		text14: 'Monthly Rewards',
		text15: 'Membership Upgrade Rewards',
		text16: 'Withdrawal Limit per Transaction'
	},
	login: {
		title1: 'Enter the email',
		title2: 'Enter the password',
		title3: 'Forgot password',
		title4: 'Sign in',
		title5: 'Captcha',
		title6: 'Next',

		title7: 'Invitation code',
		text1: 'Get Verification Code',
		text2: 'Retrieve Again',
		text3: 'Seconds to Retrieve Again',
		text4: 'Verification Code Sent',

		text5: 'Username is required',
		text6: 'Please enter a valid email address',
		text7: 'Password is required',
		text8: 'Password must be between 6 and 20 characters long',
		text9: 'Verification code must be between 4 and 6 characters long',
		text10: 'Forgot Password',
		text11: 'Verification code is required',
		text12: 'Seconds later to retrieve',
		text13: 'Password',
		text14: 'Username'
	},
	account: {
		text1: 'Deposit',
		text2: 'Withdraw',
		text3: 'Quota',
		text4: 'Please enter',
		text5: 'Do not participate in promotional activities',
		text6: 'Withdraw news',
		text7: 'Payment problem',
		text8: 'Click for help',
		text9: 'Deposit bill',
		text10: 'Minimum deposit requirement',
		text11: 'Enter your correct deposit news',
		text12: 'The fastest money to account for 5 minutes',
		text13: 'If you have any questions, feel free to contact us.',
		text14: 'Available balance',
		text15: 'Quantity',
		text16: 'Withdraw to',
		text17: '',
		text18: 'Withdrawal bill',
		text19: 'level',
		text20: 'Number of withdrawals',
		text21: 'Withdrawal rate',
		text22: 'Minimum withdrawal amount',
		text23: 'Maximum withdrawal amount',
		text24: 'Opening time',
		text25: 'The fastest withdrawal time is 10 minutes.',
		text26: 'All fields must be filled. The data must be true and verified before withdrawal. If the data entered is incorrect, you will not receive the payment, and your balance will not be refunded. Please be careful when filling out this form!',
		text27: 'If you have any questions, please feel free to contact us.',
		text28: 'Surname',
		text29: 'Name',
		text30: 'Email',
		text31: 'Phone',
		text32: 'Tax number',
		text33: 'Account',
		text34: 'Please select',
		text35: 'Save',
	},
	msg: {
		text1: 'Incorrect email format',
		text2: 'Registration successful',
		text3: 'Login successful',
	},
	coupon: {
		text1: 'Exchange',
		text2: 'Coupon reminder',
		text3: 'Please make sure that your name and bank card are accurate and unique, if the same user uses different network IP to register multiple accounts and obtain the promo code, it will be regarded as fraud and your account will be permanently frozen.',
		text4: 'Successful exchange'
	},

	mine: {
		text1: 'Account Balance',
		text2: 'Game Amount',
		text3: 'Total Deposit',
		text4: 'Betting Points',
		text5: 'Deposit',
		text6: 'Account',
		text7: 'Other Features',
		text8: 'Billing Records',
		text9: 'Quota Conversion',
		text10: 'Match Records',
		text11: 'Settings',
		text12: 'Logout',
		text13: 'Select',
		text14: 'Transfer In',
		text15: 'Transfer Out',
		text16: 'Available Balance',
		text17: 'Transfer In Amount',
		text18: 'Transfer Out Amount',
		text19: 'Enter Amount',
		text20: 'Transfer to Wallet',
		text21: 'Transfer to Balance',
		text22: 'Game Transfer to Balance',
		text23: 'Current Account',
		text24: 'Submit',
		text25: 'Successful operation',
		text26: 'Delete all read',
		text27: 'Successful replication'
	},

	paycard: {
		title1: 'Last Name',
		title2: 'First Name',
		title3: 'Email',
		title4: 'Phone Number',
		title5: 'Tax ID',
		title6: 'Please enter',
		title7: 'Account',
		title8: 'Account Type',
		title9: 'Invalid Format',
		title10: 'Please select'
	}

}