<template>
  <el-dialog
    :visible.sync="showConversionDialog"
    width="420px"
    top="10vh"
    custom-class="no-header-dialog"
    :show-close="false"
    @close="$store.dispatch('setConversionDialog', false)"
  >
    <div class="conversion_sty d_border12">
      <div class="one d_flex">
        <div>{{ $t("mine.text13") }}:</div>
        <div class="quota_conversion game_flex">
          <div class="game_flex align_center game_justify_center">
            <span>{{ $t("mine.text1") }}:</span>
            <span>{{ gameAmount.money || "0" }}</span>
          </div>
          <div class="game_flex align_center game_justify_center">
            <span>{{ $t("mine.text2") }}:</span>
            <span>{{ gameAmount.money || "0" }}</span>
          </div>
        </div>

        <el-radio-group v-model="walletTransferForm.status">
          <el-radio :label="1">{{ $t('mine.text20') }}</el-radio>
          <el-radio :label="2">{{ $t('mine.text21') }}</el-radio>
          <el-radio :label="3">{{ $t('mine.text21') }}</el-radio>
      </el-radio-group>


      </div>
      <div class="tow" v-if="walletTransferForm.status != 3">
        <div>
          {{
            walletTransferForm.status == "1"
              ? $t("mine.text20")
              : $t("mine.text21")
          }}
        </div>
        <el-input
          :placeholder="$t('mine.text19')"
          v-model="walletTransferForm.money"
        ></el-input>
      </div>
      <div class="three">{{ $t("mine.text23") }}: {{ userInfo.money }}</div>
      <div class="four">
        <el-button style="width: 100%;" type="primary" @click="getWalletTransfer">
          {{ $t("mine.text24") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { homeApi } from "@/api/home.js";
import { mapGetters } from "vuex";
export default {
  name: "game-conversion",
  computed: {
    ...mapGetters(["userInfo", "showConversionDialog"]),
  },
  data() {
    return {
      walletTransferForm: {
        // status = 1 转入 2 转出
        status: 1,
        money: "",
      },
      gameAmount: {},
    };
  },

  methods: {
    // 游戏转入
    async getWalletTransfer() {
      let { code } = await homeApi.walletTransfer(this.walletTransferForm);
      if (code == 200) {
        this.walletTransferForm = {
          status: "1",
          money: "",
        };
        this.$store.dispatch("setConversionDialog", false);
        // this.$store.dispatch('GetInfo')
      }
    },
    async getGameAmount() {
      let { code, data } = await homeApi.gameAmount();
      if (code == 200) {
        this.gameAmount = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conversion_sty {
  padding: 10px;
  width: 100%;
  color: #fff;
  font-size: 14px;

  ::v-deep .u-radio {
    margin-right: 50px;
    color: #fff;
  }

  .one,
  .tow {
    flex-direction: column;

    & > div:nth-child(1) {
      height: 30px;
      line-height: 30px;
    }
  }

  .tow {
    margin: 30px 0;
  }

  .three {
    height: 30px;
    line-height: 30px;
  }

  .four {
    margin-top: 10px;
  }

  .quota_conversion {
    justify-content: space-around;
    & > div {
      align-items: center;
      height: 60px;
    }
  }

  ::v-deep .el-radio{
    color: #fff;
  }
}
</style>