<template>
  <div class="game_popover_info">
    <div class="user_head game_flex">
      <el-avatar :src="userInfo.avatar" size="large"></el-avatar>
      <div class="head_fr game_border_r">
        <div class="fr_one game_flex game_align_center game_border_r">
          <span>{{ userInfo.username }}</span>
          <i class="el-icon-document-copy" @click.stop="copyData(userInfo.username)"></i>
        </div>
        <span style="padding-left: 10px;">
          ID: {{ userInfo.id }}
          <i class="el-icon-document-copy" @click.stop="copyData(userInfo.id + '')" style="cursor: pointer;"></i>
        </span>
        <!-- <div class="fr_one game_flex game_align_center game_border_r">
          <span>{{ userInfo.username }}</span>
          <i class="el-icon-document-copy" @click="copyData"></i>
        </div> -->
      </div>
    </div>

    <div class="process_wrapper game_border_r">
      <div class="pr_wr_top game_flex align_center">
        <div class="game_flex game_align_center">
          <el-image
            :src="$config.baseImgUrl + userInfo.vip_img"
            style="width: 30px"
          ></el-image>
          <span style="margin-left: 10px"
            >{{ $t("vip.text5") }}-{{ userInfo.level }}</span
          >
        </div>
        <div class="game_flex align_center"></div>
      </div>
      <div class="pr_wr_con game_border_r">
        <div class="wr_con_top game_flex game_align_center">
          <div>
            {{ $t("userinfo.text11") }}
          </div>
          <div>
            <span>{{ userInfo.bet }}</span>
            <!-- <span style="color: #ecba81; font-weight: 700;"> 500,00</span> -->
          </div>
        </div>
        <div class="wr_con_foo">
          <el-progress
            height="10"
            :percentage="userInfo.bet_b"
            activeColor="#ebebeb"
          ></el-progress>
        </div>

        <div class="wr_con_top game_flex game_align_center">
          <div>
            {{ $t("userinfo.text12") }}
          </div>
          <div>
            <!-- <span>R$ 0.00/</span> -->
            <span>{{ userInfo.recharge }}</span>
            <!-- <span style="color: #ecba81; font-weight: 700;"> 500,00</span> -->
          </div>
        </div>
        <div class="wr_con_foo">
          <el-progress
            height="10"
            :percentage="userInfo.recharge_b"
            activeColor="#ff1f49"
          ></el-progress>
        </div>
      </div>
    </div>

    <div class="user_popup_content game_border_r">
      <div
        class="item game_flex game_align_center"
        v-for="(item, i) in popupContentList"
        :key="item.name"
        @click="toPath(item, i)"
      >
        <el-image style="width: 30px; height: 30px" :src="item.url"></el-image>
        <span>{{ item.name }}</span>
      </div>
    </div>

    <div class="user_quit" @click="quitBtn">
      <span>{{ $t("jogos.text23") }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { homeApi } from "@/api/home";
export default {
  name: "GamePopoverInfo",
  props: {
    doClosePopover: {
      type: Function,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      popupContentList: [
        {
          name: this.$t("mine.text9"),
          url: require("@/assets/images/jogos/qiehuan.png"),
        },
        {
          name: this.$t("jogos.text17"),
          path: "/message",
          url: require("@/assets/images/jogos/message.png"),
        },
        {
          name: this.$t("jogos.text18"),
          url: require("@/assets/images/jogos/recharge-1.png"),
        },
        {
          name: this.$t("jogos.text19"),
          url: require("@/assets/images/jogos/withdraw.png"),
        },
        {
          name: this.$t("jogos.text20"),
          path: "/vip",
          url: require("@/assets/images/jogos/vip.png"),
        },
        {
          name: this.$t("jogos.text21"),
          path: "/userinfo",
          url: require("@/assets/images/jogos/user.png"),
        },
        // {
        //   name: this.$t("jogos.text22"),
        //   path: "/pages/userinfo/index?span=记录",
        //   url: require("@/assets/images/jogos/record.png"),
        // },
      ],
    };
  },
  methods: {
    quitBtn() {
      this.doClosePopover();
      this.$store.dispatch("LogOut");
      this.$delay(100).then(() => {
        this.$router.push({ path: "/" });
      });
    },
    // 复制内容
    copyData(data) {
      this.$copyText(data).then(() => {
        this.$notification.success(this.$t('mine.text27'))
      })
    },

    toPath(item, i) {
      // 额度转换
      if (i == 0) {
        return this.$store.dispatch("setConversionDialog", true);
      }
        // 存款
        if (i == 2) {
        this.$store.dispatch("setAccountDialog", true);
        return this.$store.dispatch("setAccessMode", 0);
      }
      // 取款
      if (i == 3) {
        this.$store.dispatch('setAccessMode', 1)
        this.$store.dispatch("setAccountDialog", true);
        return this.$store.dispatch("setAccessMode", 1);
      }
      this.doClosePopover();
      this.$delay(100).then(() => {
      this.$router.push({ path: item.path });
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.game_popover_info {
  color: #fff;
  backdrop-filter: saturate(180%) blur(10px);
  background: #201e2f;
  // .box {
  //   padding: 20px;
  //   position: fixed;
  //   width: 75vw;
  //   max-height: 900px;
  //   top: 100px;
  //   right: 80px;
  //   z-index: 99;
  //   overflow: hidden;
  //   background-color: #211e30;
  //   ::v-deep .uni-scroll-view-content {
  //     padding-bottom: 30px;
  //   }

  .user_head {
    margin: 0.0521rem 0 0.1042rem 0;

    .head_fr {
      padding-bottom: 10px;
      margin-left: 30px;
      flex: 1;
      background: #2c293a;

      .fr_one {
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        background-color: #2b293a;
        cursor: pointer;
      }

      .fr_tow {
        padding: 4px 10px;
        display: inline-block;
        font-size: 12px;
        background-color: #fa1e44;
      }
    }
  }
  // }

  .process_wrapper {
    margin: 20px 0;
    height: 140px;

    .pr_wr_top {
      height: 40px;
      justify-content: space-between;

      & > span {
        & > span {
          color: #ecba81;
          font-weight: 700;
        }
      }
    }

    .pr_wr_con {
      padding: 10px;
      background-color: #2c293b;

      .wr_con_top {
        height: 25px;
        justify-content: space-between;
      }
    }
  }

  .user_popup_content {
    background-color: #2b293a;

    .item {
      padding: 0 20px;
      height: 55px;
      color: #adb6c4;
      font-size: 12px;
      cursor: pointer;
      & > span {
        margin-left: 10px;
      }
    }
  }

  .user_quit {
    // position: absolute;
    // bottom: 0;
    height: 40px;
    line-height: 0.3125rem;
    font-size: 14px;
    color: #374962;
    cursor: pointer;
  }
}
</style>