import request from '@/utils/request'


export const homeApi = {

    // 公告
    notice: () => {
        return request({
            url: 'index/getchat',
            method: 'POST'
        })
    },

    // 首页轮播
    banner: () => {
        return request({
            url: 'index/getban',
            method: 'POST'
        })
    },

    // 游戏列表
    gameList: (data) => {
        return request({
            url: 'index/game_index',
            method: 'POST',
            data
        })
    },

    // 进入游戏
    enterGame: (data) => {
        return request({
            url: 'index/in_game',
            method: 'POST',
            data
        })
    },

    // 活动详情
    eventDetails: (type) => {
        return request({
            url: 'index/get_contents',
            method: 'POST',
            data: {
                type
            }
        })
    },

    // 获取联系方式
    information: () => {
        return request({
            url: 'index/get_c',
            method: 'GET',
        })
    },

    // 获取某个分类下游戏列表
    gameLists: (data) => {
        return request({
            url: 'index/game_detai',
            method: 'POST',
            data
        })
    },

    // 获取下载地址
    downloadAddress: () => {
        return request({
            url: 'index/get_c',
            method: 'POST',
        })
    },

    // 模糊查询游戏
    fuzzyQueryGame: (data) => {
        return request({
            url: 'index/game_like',
            method: 'POST',
            data
        })
    },

    // 登录
    login: (data) => {
        const { account, password } = data
        return request({
            url: 'user/login',
            method: 'POST',
            data: {
                account,
                password
            }
        })
    },

    // 退出
    logout: () => {
        return request({
            url: '/logout',
            method: 'POST',
        })
    },

    // 注册
    register: (data) => {
        return request({
            url: 'user/register',
            method: 'POST',
            data
        })
    },

    // 获取验证码
    verificationCode: (data) => {
        return request({
            url: 'index/send_email_mobile',
            method: 'POST',
            data
        })
    },

    // 获取验证码
    walletTransfer: (data) => {
        return request({
            url: 'index/walletTransfer',
            method: 'POST',
            data
        })
    },
    // 获取游戏金额
    gameAmount: () => {
        return request({
            url: 'index/get_wallet',
            method: 'GET'
        })
    },
    // 获取用户详细信息
    getInfo: () => {
        return request({
            url: 'user/getUserinfo',
            method: 'POST'
        })
    },

    // 获取左侧弹窗列表
    getLeft: () => {
        return request({
            url: 'index/get_left',
            method: 'POST'
        })
    },

    // 查看是否被收藏
    lookLike: (game_id) => {
        return request({
            url: 'index/look_like',
            method: 'POST',
            data: { game_id }
        })
    },

    // 收藏游戏
    likeGame: (game_id) => {
        return request({
            url: 'index/like_game',
            method: 'POST',
            data: { game_id }
        })
    },

    // 取消收藏游戏
    cLikeGame: (game_id) => {
        return request({
            url: 'index/c_like_game',
            method: 'POST',
            data: { game_id }
        })
    },

    // 获取收藏列表
    getGame: () => {
        return request({
            url: 'index/get_game',
            method: 'POST'
        })
    },

    // 底部公共说明
    commonalityInstruc: (type) => {
        return request({
            url: 'index/get_contents',
            method: 'POST',
            data: { type }
        })
    },

    // 获取底部列表
    foolDetails: () => {
        return request({
            url: 'index/get_fool',
            method: 'POST',
        })
    },

    // 获取底部列表详情
    foolLevelDetails: (id) => {
        return request({
            url: 'index/fool_dtails',
            method: 'POST',
            data: { id }
        })
    },
    // 领取优惠券
    downCoupon: (Code) => {
        return request({
            url: 'index/set_code',
            method: 'POST',
            data: { Code }
        })
    },
    // 弹窗公告
    announcementData: () => {
        return request({
            url: 'index/conts',
            method: 'POST',
        })
    },
    // 忘记密码
    forgotPassword: (data) => {
        return request({
            url: 'user/change_pwd',
            method: 'POST',
            data
        })
    },
}

