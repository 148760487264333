<template>
  <div class="game_account">
    <el-dialog
      :visible.sync="showAccountDialog"
      width="3rem"
      top="10vh"
      custom-class="no-header-dialog"
      :show-close="false"
      @close="$store.dispatch('setAccountDialog', false)"
    >
      <div class="account_tabs game_flex">
        <div
          class="game_flex game_align_center game_justify_center"
          :class="accessMode == 0 && 'curr_tabs'"
          @click="$store.dispatch('setAccessMode', 0)"
        >
          {{ $t("account.text1") }}
        </div>
        <div
          class="game_flex game_align_center game_justify_center"
          :class="accessMode == 1 && 'curr_tabs'"
          @click="$store.dispatch('setAccessMode', 1)"
        >
          {{ $t("account.text2") }}
        </div>
      </div>

      <template v-if="accessMode == 0">
        <div class="account_input">
          <div class="title">{{ $t("account.text3") }}</div>
          <el-input
            :placeholder="$t('account.text4')"
            v-model="form.money"
            :disabled="form.is_get"
          >
          </el-input>
          <div class="tips">{{ gift }}</div>
        </div>

        <div class="account_amount game_flex">
          <div
            class="amount_item game_border_r game_flex game_align_center game_content_center"
            v-for="(item, index) in rechlist"
            :key="index"
            :class="amountIndex == index && 'active_amount_item'"
            @click="changeAmountIndex(item, index)"
          >
            {{ item.money }}

            <span class="active_tips game_border_r">{{ item.gift }}</span>
          </div>
        </div>

        <div class="account_select game_border_r">
          <el-checkbox v-model="form.is_get">{{
            $t("account.text5")
          }}</el-checkbox>
        </div>

        <div
          class="account_btn game_border_r game_flex game_align_center"
          @click="withdrawRechargeBtn(0)"
        >
          <div>{{ $t("account.text1") }}</div>
          <div class="game_flex game_align_center">
            {{ form.money }}
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>

        <div class="account_foo">
          <div class="foo_one game_flex game_align_center">
            <!-- <u-icon name="question-circle" color="#fff" size="28px" style="margin-top: 8px;"></u-icon> -->
            <span style="margin: 0 10px">{{ $t("account.text7") }}?</span>
            <span style="color: #ed1c49">{{ $t("account.text8") }}</span>
          </div>
          <div class="foo_tow">
            {{ $t("account.text9") }}
          </div>
          <div class="foo_three">
            <div>{{ $t("account.text10") }}: $20</div>
            <div>1. {{ $t("account.text11") }}。</div>
            <div>2. {{ $t("account.text12") }}。</div>
            <div>3. {{ $t("account.text13") }}。</div>
          </div>
        </div>
      </template>

      <template v-if="accessMode == 1">
        <div class="revocation">
          <div class="revo_one">
            <div>{{ $t("account.text14") }}</div>
            <div>{{ userInfo.money }}</div>
            <div class="game_flex">
              {{ $t("account.text15") }}
              <!-- <u-icon name="question-circle" color="#fff" size="24px"></u-icon> -->

              <div class="tips">*</div>
            </div>
          </div>
          <div class="revo_tow">
            <el-input :placeholder="$t('account.text4')" v-model="form.money">
            </el-input>
          </div>
          <div class="revo_three" @click="showBindInfo = true">
            <div class="title">{{ $t("account.text16") }}</div>
            <el-input :placeholder="$t('account.text4')" v-model="form.money">
              <i slot="prefix" class="el-icon-s-finance"></i>
              <i slot="suffix" class="el-icon-arrow-right"></i>
            </el-input>
          </div>
          <div class="revo_four">
            <div
              class="account_btn game_border_r game_flex game_align_center"
              @click="withdrawRechargeBtn(1)"
            >
              <div>{{ $t("account.text2") }}</div>
              <div class="game_flex align_center">
                {{ form.money }}
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>

          <div class="account_foo">
            <div class="foo_one">
              <div>{{ $t("account.text18") }}</div>

              <div>
                <span style="color: #ed1c49" @click="toVipPage"
                  >VIP-{{ userInfo.level }} {{ $t("account.text19") }}</span
                >
                <span>{{ $t("account.text20") }}：{{vipsInfo.day_withdraw}}</span>
              </div>

              <div>{{$t('account.text21')}}: {{vipsInfo.withdraw}}%</div>
              <div>{{$t('vip.text12')}}：R${{vipsInfo.withdraw_limt}}</div>
              <div>{{ $t("account.text24") }}：00:00 - 24:00。</div>
              <div>1. {{ $t("account.text25") }}。</div>
              <div>2. {{ $t("account.text26") }}!</div>
              <div>3. {{ $t("account.text27") }}。</div>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      :visible.sync="showBindInfo"
      width="3rem"
      top="10vh"
      custom-class="no-header-dialog"
      :show-close="false"
      :title="$t('account.text6')"
      @close="showBindInfo = false"
    >
      <div class="bind_info">
        <div class="bind_title">{{ $t("account.text6") }}</div>
        <el-form :model="bindForm" :rules="bindRules" ref="bindForm">
          <el-form-item prop="firstname" :label="$t('account.text28')">
            <el-input
              v-model="bindForm.firstname"
              :placeholder="$t('jogos.text15')"
            >
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
            </el-input>
          </el-form-item>

          <el-form-item prop="lastname" :label="$t('account.text29')">
            <el-input
              v-model="bindForm.lastname"
              :placeholder="$t('jogos.text15')"
            >
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
            </el-input>
          </el-form-item>

          <el-form-item prop="email" :label="$t('account.text30')">
            <el-input
              v-model="bindForm.email"
              :placeholder="$t('jogos.text15')"
            >
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
            </el-input>
          </el-form-item>

          <el-form-item prop="phone" :label="$t('account.text31')">
            <el-input
              v-model="bindForm.phone"
              :placeholder="$t('jogos.text15')"
            >
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
            </el-input>
          </el-form-item>

          <el-form-item prop="cpf" :label="$t('account.text32')">
            <el-input v-model="bindForm.cpf" :placeholder="$t('jogos.text15')">
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
            </el-input>
          </el-form-item>

          <el-form-item prop="dict_key" :label="$t('account.text33')">
            <el-input v-model="bindForm.dict_key" :placeholder="$t('jogos.text15')">
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="pixtype" :label="$t('account.text34')">
            <el-select
              v-model="bindForm.pixtype"
              :placeholder="$t('jogos.text15')"
              style="width: 100%"
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="item in pixtypearray"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              class="bind_btn"
              type="primary"
              @click="subBindForm('bindForm')"
              >{{ $t("account.text35") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import {
  userinfoApi, 
	 } from '@/api/userinfo'
import { walletApi } from "@/api/wallet";
export default {
  name: "gameAccountDialog",
  computed: {
    ...mapGetters(["showAccountDialog", "userInfo", "accessMode"]),
  },
  data() {
    return {
      amount: "",
      amountIndex: 0, 
      rechlist: [],
      form: {
        // 0 充值  1 提现
        status: "0",
        // 是否充值奖励
        is_get: false,
        // 金额
        money: "",
      },
      gift: "",

      bindForm: {
        // 姓
        firstname: "",
        // 名
        lastname: "",
        // 邮箱
        email: "",
        // 手机号
        phone: "",
        // 税号
        cpf: "",
        // 账号
        dict_key: "",
        // pix类型
        pixtype: "",
      },
      showBindInfo: false,
      retrieveProcedure: 1,
      vipsInfo: {},
      bindRules: {
        firstname: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
        lastname: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
        email: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
        phone: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
        cpf: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
        dict_key: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
        pixtype: [
          { required: true, message: this.$t("login.text5"), trigger: "blur" },
        ],
      },

      pixtypearray: [
        { label: "CPF", value: "CPF" },
        { label: "CNPJ", value: "CNPJ" },
        { label: "PHONE", value: "PHONE" },
        { label: "EIAIL", value: "EIAIL" },
        { label: "EVP", value: "EVP" },
      ],
    };
  },
  created() {
    this.getRechlist();
    this.getGamevipsInfo()
  },
  methods: {
    async changeAmountIndex(item, index) {
      this.amountIndex = index;
      if (this.form.is_get) {
        this.form.money = item.true_money;
        this.gift = item.gift;
      } else {
        this.form.money = item.money;
      }
    },
    async withdrawRechargeBtn(status) {
      const resolveDispatch = () => {
        this.$store.dispatch("getUserInfo");
        this.$store.dispatch("setAccountDialog", false);
      };

      this.form.status = status;
      let data;

      if (status == 1) {
        let res1 = await walletApi.isBind();
        if (res1.code != 200) return;

        data = await walletApi.withdrawRecharge(this.form);
      } else {
        data = await walletApi.withdrawRecharge(this.form);
      }
      if (data.code == 200) {
        resolveDispatch();
        if (data.data.paymentUrl) {
          this.form.money = "";
          this.$dDelay(200).then(() => {
            window.location.href = data.data.paymentUrl;
          });
        }
      }
    },
    async getRechlist() {
      let { code, data } = await walletApi.rechlist();
      if (code == 200) {
        this.rechlist = data;
      }
    },

    toVipPage() {},

    subBindForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let { code } = await walletApi.addBind(this.bindForm);
          if (code == 200) {
            this.$notification.success(this.$t("mine.text25"));
            this.$store.dispatch("getUserInfo");
            this.showBindInfo = false;
          }
        }
      });
    },

    async getGamevipsInfo(){
				let {code,data} = await userinfoApi.gamevipsInfo()
				if(code == 200){
					this.vipsInfo = data
				}
			},
  },

  watch: {
    "form.is_get"() {
      this.form.money = "";
    },
    "accessMode"(){
    this.getGamevipsInfo()
    }
  },
};
</script>
  
  <style lang="scss" scoped>
.game_account {
  width: 4rem;
  .account_tabs {
    margin: 20px 0;
    border-radius: 20px;
    color: #fff;
    background-color: #2b2939;
    & > div {
      padding: 0 20px;
      height: 0.2083rem;
      cursor: pointer;
    }

    .curr_tabs {
      background-color: #2282f5;
      border-radius: 30px;
      background-image: linear-gradient(to right, #7b3efa, #5f13ff);
    }
  }

  .account_input {
    position: relative;

    .title {
      padding-left: 0.1563rem;
      position: relative;
      height: 0.3604rem;
      line-height: 0.3604rem;
      color: #fff;
      &::before {
        content: "*";
        position: absolute;
        left: 0;
        top: 8%;
        font-size: 20px;
        color: darkred;
        font-weight: 700;
      }
    }

    .tips {
      position: absolute;
      right: 10px;
      top: 70%;
      font-size: 20px;
      color: #4aa459;
      font-weight: 700;
      transform: translateY(-50%);
    }

    ::v-deep .el-input {
      background-color: #201e2e !important;
    }
  }

  .account_amount {
    flex-wrap: wrap;
    color: #fff;
    .amount_item {
      position: relative;
      margin-top: 0.0521rem;
      width: 30%;
      height: 0.36rem;
      cursor: pointer;
      background-color: #24272d;
      border: 1px solid #24272d;

      .active_tips {
        position: absolute;
        top: -4px;
        right: 0px;
        padding: 0 6px;
        font-size: .0729rem;
        color: #fff;
        background-color: #4aa359;
      }
    }

    & .amount_item:nth-child(3n + 2) {
      margin: 0.0521rem 0.1042rem;
    }

    .active_amount_item {
      background-color: #241b47;
      border-color: #2282f5;
    }
  }

  .account_select {
    margin: 20px 0;
    padding: 10px;
    background-color: #2b2939;
  }

  .account_btn {
    padding: 0 10px;
    height: 0.2604rem;
    font-size: 14px;
    font-weight: 700;
    justify-content: space-between;
    color: #fff;
    background: linear-gradient(360deg, #9b81ff 0%, #2282f5 100%);
  }

  .account_foo {
    .foo_one {
      min-height: 0.2604rem;
      font-size: 14px;
      color: #fff;
      & > div:nth-child(1) {
        margin: 20px 0 10px 0;
        color: #fff;
      }
    }
    .foo_tow,
    .foo_three {
      color: #fff;
      line-height: 22px;
    }
    .foo_tow {
      margin-bottom: 10px;
    }
  }

  .revocation {
    color: #fff;
    .revo_one {
      margin-top: 0.1042rem;

      & > div:nth-child(1) {
        font-size: 0.133rem;
      }

      & > div:nth-child(2) {
        margin: 5px 0;
        font-size: 0.12rem;
      }

      & > div:nth-child(3) {
        position: relative;

        .tips {
          position: absolute;
          right: 70%;
          top: 70%;
          font-size: 16px;
          color: #d1374c;
          font-weight: 700;
          transform: translateY(-50%);
        }
      }

      & > view:nth-child(4) {
      }
    }

    .revo_tow {
      margin: 0.1042rem 0 0.1563rem 0;
    }

    .revo_three {
      margin-bottom: 50px;

      .title {
        padding-left: 0.1563rem;
        position: relative;
        height: 0.3125rem;
        line-height: 0.3125rem;

        &::before {
          content: "*";
          position: absolute;
          left: 0;
          top: 15%;
          font-size: 16px;
          color: darkred;
          font-weight: 700;
        }
      }
    }
  }

  .bind_info {
    .bind_title {
      margin-bottom: 20px;
      height: 0.2604rem;
      line-height: 0.2604rem;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
    }

    .bind_btn {
      width: 100%;
    }

    ::v-deep .el-input__prefix,
    ::v-deep .el-input__suffix {
      top: 0;
    }
  }

  ::v-deep .el-input__prefix,
  ::v-deep .el-input__suffix {
    top: 12px;
  }
}
</style>