import {
	homeApi
} from '@/api/home'
export default {
	state: {
		userInfo: {},
		token: '',
		language: 'portugal'
	},
	mutations: {
		setUserInfo(state, data) {
			state.userInfo = data
			state.token = data.token
		},
		LogOut(state) {
			state.userInfo = {}
			state.token = ''
		},
		setToken(state, token) {
			state.token = token
		},
		setLanguage(state, language) {
			state.language = language
		}
	},
	actions: {
		// 设置用户信息
		setUserInfo({
			commit
		}, data) {
			commit('setUserInfo', data)
			commit('setToken', data?.token)
		},
		// 退出
		LogOut({
			commit
		}) {
			commit('LogOut')
		},
		// 设置语言
		setLanguage({
			commit
		}, language) {
			return new Promise((res) => {
				commit('setLanguage', language)
				res()
			})
		},
		// 获取用户详细信息
		getUserInfo({
			dispatch,
			state
		}) {
			if(!state.token) return
			return new Promise((resolve, reject) => {
				homeApi.getInfo().then(res => {
					dispatch('setUserInfo', res.data)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		}
	},
}