<template>
  <div
    class="game_susp game_border6 game_flex game_align_center"
    @click="$router.push({ path: '/telegram' })"
  >
    <el-image
      style="width: 0.5rem; height: 0.5rem"
      :src="require('@/assets/images/jogos/dianban1.png')"
    ></el-image>
  </div>
</template>

<script>
export default {
  name: 'gameSusp'
};
</script>

<style lang="scss" scoped>
.game_susp {
  padding-top: 0.1042rem;
  position: fixed;
  right: 6px;
  bottom: 0.5208rem;
  width: 0.625rem;

  flex-direction: column;
  justify-content: space-around;

  cursor: pointer;

  & > span {
    margin-bottom: 0.0521rem;
  }
}
</style>