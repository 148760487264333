import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters' 
import user from './modules/user' 
import config from './modules/config'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    user,
    config
  },
   /* vuex数据持久化配置 */
   plugins: [
    createPersistedState()
  ],
  getters,
})
