export default {
    state: {
        showLoginDialog: false,
        showLeftMenu: true,
        showAccountDialog: false,
        showConversionDialog: false,
        showAnnouncementDialog: false,

        loginNavIndex: 1,
        accessMode: 0
      },
      mutations: {
        setLoginDialog(state, type) {
          state.showLoginDialog = type
        },
        setShowLeftMenu(state, type) {
          state.showLeftMenu = type
        },
        setAccountDialog(state, type) {
          state.showAccountDialog = type
        },
        setConversionDialog(state, type) {
          state.showConversionDialog = type
        },
        setAnnouncementDialog(state, type) {
          state.showAnnouncementDialog = type
        },

        setLoginNavIndex(state, index){
          state.loginNavIndex = index
        },

        setAccessMode(state, index){
          state.accessMode = index
        }
      },
      actions: {
        // 登录弹窗
        setLoginDialog({ commit }, type) {
          commit('setLoginDialog', type)
        },
        // 左侧菜单
        setShowLeftMenu({ commit }, type) {
          commit('setShowLeftMenu', type)
        },
        setAccountDialog({ commit }, type) {
          commit('setAccountDialog', type)
        },
        setConversionDialog({ commit }, type) {
          commit('setConversionDialog', type)
        },
        setAnnouncementDialog({commit}, data){
          commit('setAnnouncementDialog', data)
        },
        setLoginNavIndex({commit}, index){
          commit('setLoginNavIndex', index)
        },
        setAccessMode({commit}, index){
          commit('setAccessMode', index)
        },
      },
}